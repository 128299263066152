import { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-number-input";

import { FormFieldInputError, ExternalLink } from "@atd/components";
import { AccountSchema } from "@atd/schema";
import { useAddAccountMutation } from "@atd/features/user";
import { toast } from "react-toastify";
import { getErrorMessage } from "@atd/utils";
import { checkCredentialCookies, getUserGeoLocationDetails } from "@atd/utils";

import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPlan, setFormSubmitting } from "../../features";

const ErrorMessage = styled(FormFieldInputError)`
  text-align: left;
  margin-top: -10px;
  margin-bottom: 15px;
`;

const CustomErrorMessage = styled.div`
  text-align: left;
  margin-top: -10px;
  margin-bottom: 15px;
`;

const PhoneInputStyle = styled(PhoneInput)`
  margin-bottom: 1em;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.25rem;
  padding-left: 8px;
  background: white;

  input[type="tel"] {
    margin-bottom: 0 !important;
    border: 0 !important;
  }
`;

function AccountSetup() {
  const [searchParams] = useSearchParams();

  const planId = searchParams.get("plan_id");
  const coupon = searchParams.get("coupon");
  const coupon_code = searchParams.get("deals");
  const offers = searchParams.get("offers");

  const refRecaptcha = useRef(null);
  const emailRef = useRef(null);
  const dispatch = useDispatch();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [country, setCountry] = useState({});

  const navigate = useNavigate();

  const { currentPlan } = useSelector((store) => store.auth);
  // console.log(currentPlan);
  const [addAccount] = useAddAccountMutation();

  useEffect(() => {
    dispatch(setCurrentPlan(planId));
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (checkCredentialCookies()) {
      navigate("/requests");
    }
  }, [navigate]);

  useEffect(() => {
    getUserGeoLocationDetails().then((country) => {
      setCountry(country);
    });
  }, []);

  useEffect(() => {
    const emailField = emailRef.current;

    // Handle clear events (input has changed or an API error was returned)
    emailField.addEventListener("nb:clear", function (e) {
      // Check for errors
      if (e.detail.result && e.detail.result.isError()) {
        if (e.detail.result.isThrottled()) {
        }
        setIsValidEmail(false);

        // Do stuff when other API errors occur
        // - Our recommendation is to hide any loaders and treat these emails the same way you would treat an Unknown email
      }

      // Do stuff when input changes, (e.g. hide loader)
    });

    // Handle results (API call has succeeded)
    emailField.addEventListener("nb:result", function (e) {
      // Check the result
      if (e.detail.result.is(window._nb.settings.getAcceptedStatusCodes())) {
        // Do stuff for good email
        setIsValidEmail(true);
      } else {
        setIsValidEmail(false);
        // Do stuff for bad email
      }
    });
  });

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      // Validate captcha
      // if (!validateCaptcha(values.captcha, false)) {
      //   toast.error("Captcha Does Not Match");
      //   return;
      // }
      dispatch(setFormSubmitting(true));

      const updatedValue = { ...values, recaptcha: undefined };
      delete updatedValue.first_name;
      delete updatedValue.last_name;
      const response = await addAccount(updatedValue).unwrap();
      const { status, results } = response;

      if (status === "success") {
        // resetForm({});
        navigate(
          `/confirm-plan?plan_id=${currentPlan ?? planId ?? "s_1"}&u_id=${
            results.id
          }${coupon ? `&coupon=${coupon}` : ""}${
            coupon_code
              ? `&deals=${coupon_code}`
              : offers
              ? `&offers=${offers}`
              : ""
          }`,
        );
        return;
      }

      if (status === "error") {
        const errorMessages = getErrorMessage(response);
        toast.error(errorMessages);
      }
    } catch (error) {
      toast.error("We couldn't add your account, try again!");
    } finally {
      setSubmitting(false);
      dispatch(setFormSubmitting(false));
    }
  };

  const initialValues = {
    full_name: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    company_name: "",
    plan_id: currentPlan || planId || "s_1",
    recaptcha: "",
    phone: null,
  };

  return (
    <>
      {/* <CreateAccount></CreateAccount> */}

      <div className="container_cls">
        <AccountSetupStyle
          title="Setup your Account"
          description={
            <p>
              Unlimited designs, Dedicated designers, World-class customer
              experience and much more at just a click away!
            </p>
          }
        >
          <Formik
            initialValues={initialValues}
            validationSchema={AccountSchema}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={true}
          >
            {({
              values,
              isSubmitting,
              setFieldValue,
              setSubmitting,
              handleBlur,
              errors,
            }) => {
              const onBlur = (e) => {
                if (!values.recaptcha) {
                  refRecaptcha.current.execute();
                  setSubmitting(true);
                }
                handleBlur(e);
              };

              const onValueChange = (phoneNumber) => {
                setFieldValue("phone", phoneNumber);
              };
              const onChangeValue = (firstName, lastName) => {
                setFieldValue("first_name", firstName);
                setFieldValue("last_name", lastName);
                setFieldValue("full_name", `${firstName} ${lastName}`);
              };

              return (
                <Form id="createAccountForm" className="ca-form-block p-0">
                  {/* first name */}
                  <div className="div-block-146">
                    <div className="width-50">
                      <div className="input_label">First Name</div>
                      <Field
                        name="first_name"
                        className={
                          errors.first_name ? "FormField__input--hasError" : ""
                        }
                        placeholder="First Name"
                        onBlur={onBlur}
                        onChange={(e) =>
                          onChangeValue(e.target.value, values.last_name)
                        }
                      />
                      <ErrorMessage name="first_name" />
                    </div>
                    <div className="width-50">
                      <div className="input_label">Last Name</div>
                      <Field
                        name="last_name"
                        className={
                          errors.last_name ? "FormField__input--hasError" : ""
                        }
                        placeholder="Last Name"
                        onBlur={onBlur}
                        onChange={(e) =>
                          onChangeValue(values.first_name, e.target.value)
                        }
                      />
                      <ErrorMessage name="last_name" />
                    </div>
                  </div>

                  {/* company name */}
                  <div>
                    <div className="input_label">Company name</div>
                    <Field
                      name="company_name"
                      className={
                        errors.company_name ? "FormField__input--hasError" : ""
                      }
                      placeholder="Company Name"
                      onBlur={onBlur}
                    />
                    <ErrorMessage name="company_name" />
                  </div>

                  {/* email field */}
                  <div>
                    <div className="input_label">Email address</div>
                    <Field
                      innerRef={emailRef}
                      name="email"
                      placeholder="Email"
                      className={
                        !isValidEmail || errors.email
                          ? "FormField__input--hasError"
                          : ""
                      }
                      onBlur={onBlur}
                    />
                    {!isValidEmail ? (
                      <CustomErrorMessage className="FormField__input--error">
                        Invalid Email
                      </CustomErrorMessage>
                    ) : (
                      <ErrorMessage name="email" onBlur={onBlur} />
                    )}
                  </div>

                  {/* phone number filed */}
                  <div>
                    <div className="input_label">Phone Number</div>
                    <PhoneInputStyle
                      international
                      defaultCountry={country.country_code}
                      placeholder="Phone number"
                      className={
                        errors.phone ? "FormField__input--hasError" : ""
                      }
                      value={values.phone}
                      onChange={onValueChange}
                    />
                    <ErrorMessage name="phone" />
                  </div>

                  {/* password field */}
                  <div>
                    <div className="input_label">Password</div>
                    <Field
                      type="password"
                      className={
                        errors.password ? "FormField__input--hasError" : ""
                      }
                      name="password"
                      placeholder="Password (Minimum 8 characters)"
                      onBlur={onBlur}
                    />
                    <ErrorMessage name="password" />
                  </div>

                  {/* user agreement  */}
                  <div>
                    <div className="terms_link">
                      <input type="checkbox" id="agrey" required />
                      <span htmlFor="agrey">
                        I agree to All Time Design{" "}
                        <ExternalLink
                          target="_blank"
                          href="https://alltimedesign.com/terms"
                        >
                          Terms of service
                        </ExternalLink>{" "}
                        and{" "}
                        <ExternalLink
                          href="https://alltimedesign.com/privacy/"
                          target="_blank"
                        >
                          Privacy policy
                        </ExternalLink>
                      </span>
                    </div>
                  </div>
                  {/* <div className="form-field agree">
                    <label className="w-checkbox checkbox-field">
                      <span
                        className="checkbox-label w-form-label"
                        for="__agree"
                      >
                        <input type="checkbox" className="ckalign" required /> I
                        agree to All Time Design’s
                        <a
                          target="_blank"
                          href="https://alltimedesign.com/terms"
                          className="jsModalTrigger color--purple tos w--current"
                          rel="noreferrer"
                        >
                          Terms of service
                        </a>
                        and
                        <a
                          href=""
                          className="jsModalTrigger color--purple pp w--current"
                        >
                          Privacy policy
                        </a>
                        .
                      </span>
                    </label>
                  </div> */}

                  {/* recaptch */}
                  <ReCAPTCHA
                    ref={refRecaptcha}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(value) => {
                      setFieldValue("recaptcha", value);
                      setSubmitting(false);
                    }}
                    size="invisible"
                  />
                </Form>
              );
            }}
          </Formik>
        </AccountSetupStyle>
      </div>
    </>
  );
}

export default AccountSetup;

const AccountSetupStyle = styled.div`
  .input_label {
    line-height: 2.5;
    font-size: 15px;
    font-weight: 600;
  }
  ::placeholder {
    color: #cfd4da;
    font-size: 15px;
  }
  input:not(input[type="checkbox"]) {
    width: 100%;
    height: 50px;
    border: 1px solid #cfd4da;
    border-radius: 4px;
    padding: 0 1rem;
    margin-bottom: 1rem;
  }
`;
