import moment from "moment";
import uuid from "react-uuid";

import { ExternalLink } from "@atd/components";

import { TitleStyle, Table } from "./Payment.style";

function InvoiceHistory({ data }) {
  return (
    <>
      <TitleStyle>Invoice History</TitleStyle>
      <Table className="table wt-100 mgb10">
        <thead>
          <tr className="left">
            <th className="title2">DATE</th>
            <th className="title2">DESCRIPTION</th>
            <th aria-label="hidden" className="title2" />
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 && (
            <tr className="center">
              <td colSpan={3}>No Invoice History</td>
            </tr>
          )}
          {data &&
            data.length > 0 &&
            data.map((invoice) => (
              <tr key={uuid()}>
                <td>{moment(invoice.date).format("MM-DD-YYYY")}</td>
                <td>
                  <div className="bl-cont">
                    Invoice for {moment(invoice.date).format("MMMM DD, YYYY")}
                  </div>
                </td>
                <td>
                  <ExternalLink to={invoice.invoice_url} target="_blank">
                    Download
                  </ExternalLink>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default InvoiceHistory;
