import * as React from "react";

function SvgPdf(props) {
  return (
    <svg
      id="pdf_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {
          ".pdf_svg__st8{fill:#fefdfd}.pdf_svg__st11{fill:#fefcfc}.pdf_svg__st13{fill:#2b2b2b}"
        }
      </style>
      <g id="pdf_svg__Zutq0U_00000119816876212938959800000001261090816457292438_">
        <path
          d="M199.2 233.1H44.3c-2.3-1.1-5-1.1-7.2-2.6-6.2-4.2-9.2-9.8-8.6-17.3V20.7C27.7 9.1 38.4 1.3 48.2 1.4c38.3.4 76.5.2 114.8.1 4.7 0 8.5 1.4 11.8 4.7 12.5 12.4 24.9 24.9 37.3 37.3 3.8 3.8 4.4 8.6 4.4 13.5.1 51.5.1 103 0 154.6 0 4-.1 8.2-2.3 11.9-3.5 5.8-8.5 8.8-15 9.6z"
          style={{
            fill: "#fe2116",
          }}
        />
        <path
          d="M28 20.1c.2-.1.4-.1.7-.1 0 1.7.1 3.3.1 5v185.4c0 1.4-.1 2.8-.1 4.2-.3.3-.5.3-.6-.1C28 149.7 28 84.9 28 20.1z"
          style={{
            fill: "#fe9793",
          }}
        />
        <path
          className="pdf_svg__st8"
          d="M28 214.5c.2.2.4.2.6.1 1.2 7.8 5.5 13.1 12.5 16.6 1.1.5 2.6.4 3.1 1.8-4.8 0-9.5-.1-14.3.1-1.7.1-2-.3-2-2 .2-5.5.1-11 .1-16.6z"
        />
        <path
          d="M35.7 117.5V25.3c0-10.4 5.7-16.1 16.3-16.1 35.7 0 71.4 0 107.1-.1 4.3 0 7.6 1.2 10.6 4.3C181 25 192.5 36.4 204 47.8c3.3 3.2 4.6 6.8 4.6 11.3-.1 50 0 100-.1 150 0 11-5.6 16.5-16.7 16.5H52.3c-11.3 0-16.7-5.5-16.7-16.8.1-30.3.1-60.8.1-91.3z"
          style={{
            fill: "#fefefe",
          }}
        />
        <path
          d="M126.2 61.5c-.2 7.4-1.9 13.7-3.8 20-.6 2-.4 3.6.7 5.4 3.2 4.8 6.5 9.5 10.5 13.8 1.7 1.8 3.2 2.5 5.7 2 6.5-1.1 13-1.5 19.5-.2 1 .2 2 .5 3 .8 4.7 1.7 6.7 4.1 5.9 7.7-1 4.3-4.5 4.5-7.9 4.5-8.3.1-15.6-3-21.8-8.3-1.8-1.5-3.4-1.9-5.6-1.4-6.4 1.4-12.7 3-19 4.9-2.4.7-3.9 1.9-5.2 4.1-3.5 6.3-7.6 12.2-12.9 17.2-2.4 2.2-5 4.1-8.2 4.9-3.4.8-6.5.2-8.6-2.8-2.2-3.2-.6-6 1.6-8.3 5.2-5.6 11.9-8.8 18.8-11.7 2.9-1.2 4.8-3 6.2-5.8 4.2-8.5 7.6-17.4 10.9-26.3.6-1.8.4-3.1-.3-4.7-2.7-6.5-4.4-13.2-3.8-20.3.4-4.9 2.3-7.3 5.6-7.6 3.8-.4 6.6 1.9 8 6.4.6 2.1.6 4.3.7 5.7z"
          style={{
            fill: "#fe1f14",
          }}
        />
        <path
          className="pdf_svg__st8"
          d="M121 86.8c3.7 6.1 7.6 11.4 12.6 16.6-7.4 1.8-14.4 3.2-22 5.9 3.7-7.6 6.4-14.7 9.4-22.5z"
        />
        <path
          className="pdf_svg__st11"
          d="M137.6 104.7c7.1-1 13-1.8 19-.7 2.9.5 5.1 1.9 5 5.3-.1 2.7-2.8 4.6-6.3 4.2-6.5-.8-11.9-4.2-17.7-8.8zM100.3 115.9c-2.9 5.5-6.2 10.4-10.6 14.6-1.2 1.1-2.5 2.2-3.9 3.1-1.9 1.2-3.9 1.6-5.5-.3-1.5-1.8-.8-3.6.5-5.3.7-.9 1.5-1.8 2.3-2.5 5-4.4 10.9-7.4 17.2-9.6z"
        />
        <path
          d="M117.7 76.9c-3.1-7.4-5-14-3.9-21.2.4-2.3 1.3-4.8 4.3-4.5 2.7.3 3.3 2.6 3.4 4.9.3 6.9-1.7 13.4-3.8 20.8z"
          style={{
            fill: "#fefbfb",
          }}
        />
      </g>
      <path
        className="pdf_svg__st13"
        d="M93 173.6v13.9h-4.4v-30.9h7.2c2.1 0 3.7.1 4.7.4 1 .2 1.9.7 2.6 1.2.9.7 1.6 1.7 2.2 2.9.5 1.2.8 2.6.8 4 0 1.5-.3 2.8-.8 4.1-.5 1.2-1.2 2.2-2.2 2.9-.7.6-1.6 1-2.6 1.2-1 .2-2.6.4-4.7.4H93zm.3-4.1h1.2c2.6 0 4.4-.3 5.3-.9.9-.6 1.3-1.7 1.3-3.2 0-1.6-.5-2.8-1.4-3.5-.9-.7-2.7-1-5.2-1h-1.2v8.6zM110.2 187.5v-30.9h6.4c4.2 0 7.1.2 8.8.7 1.7.4 3.2 1.2 4.4 2.2 1.7 1.4 3 3.1 3.8 5.3.9 2.1 1.3 4.6 1.3 7.3s-.4 5.2-1.3 7.3c-.9 2.1-2.1 3.9-3.8 5.3-1.3 1-2.7 1.8-4.3 2.2-1.6.4-4.2.7-7.6.7h-7.7zm4.8-4.5h4c2.2 0 3.8-.2 4.9-.5 1.1-.3 2-.8 2.9-1.6 1.1-1 1.9-2.2 2.5-3.7.6-1.5.8-3.2.8-5.2s-.3-3.7-.8-5.2c-.6-1.5-1.4-2.7-2.5-3.7-.8-.7-1.8-1.3-3-1.6-1.1-.3-2.9-.5-5.4-.5H115v22zM140.4 187.5v-30.9h16.3v4.3h-11.5v7.7h11.5v4.4h-11.5v14.5h-4.8z"
      />
    </svg>
  );
}

export default SvgPdf;
