/* eslint-disable no-unused-vars */
import PlanCard from "./components/PlanCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import TemplateMain from "./TemplateMain";
import { useEffect, useState } from "react";

import { useGetPlansQuery } from "@atd/features/user";
import { checkCredentialCookies } from "@atd/utils";

import { useDispatch, useSelector } from "react-redux";
import {
  setActivePlanCards,
  setCurrentPlan,
  setPaymentCards,
} from "../../features";
// import axios from "axios";
import { toast } from "react-toastify";
import styled from "styled-components";
import axiosClient from "../../app/axiosClient";
import { Load, Loader } from "./components/SkeletonLoader";
import SelectService from "./SelectService";
import PaymentPage from "./PaymentPage";

const ConfirmPlanDetails = () => {
  const navigate = useNavigate();

  const [promoData, setpromoData] = useState(null);

  const [promoCode, setPromoCode] = useState(null);

  const [searchParams] = useSearchParams();
  const { currentPlan, planCards, planDetails, quantity } = useSelector(
    (store) => store.auth,
  );
  const uId = searchParams.get("u_id");
  const planId = searchParams.get("plan_id");
  const coupon = searchParams.get("coupon");
  const currentPage = searchParams.get("page");

  const { data: allPlans, isLoading } = useGetPlansQuery();

  const { offer_type, offer_value, available_plans } = promoData || {};

  const navBack = () => {
    navigate(-1);
  };

  const [page, setPage] = useState(1);
  // console.log(currentPage);
  useEffect(() => {
    if (currentPage) {
      setPage(2);
    } else {
      setPage(1);
    }
  }, [currentPage]);

  useEffect(() => {
    dispatch(setPaymentCards(planDetails?.[planId]?.name));
  }, [planDetails, planId]);

  useEffect(() => {
    if (checkCredentialCookies()) {
      navigate("/requests");
    }
  }, [navigate]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentPlan(planId));
  }, []);

  useEffect(() => {
    dispatch(setActivePlanCards(planDetails?.[planId]?.period));
  }, [planDetails, planId]);

  const actualPrice =
    Number(planDetails?.[currentPlan]?.total_offer_price) * Number(quantity);

  const totalAmmount =
    offer_type === "FIXED"
      ? actualPrice - +offer_value
      : (Number(planDetails[currentPlan]?.total_offer_price) *
          Number(quantity) *
          (100 - +offer_value)) /
        100;

  const isPlanAvailable =
    currentPlan && available_plans
      ? available_plans.includes(currentPlan || planId)
      : false;

  const onAppPromo = async () => {
    try {
      const { data } = await axiosClient.get("/promo", {
        params: {
          code: promoCode,
          plan: currentPlan === planId ? planId : currentPlan,
          dpu: quantity,
        },
      });
      if (data?.status === "error") {
        throw Error(data?.message);
      }
      setpromoData(data?.results);
      toast.success(data?.results?.description);
    } catch (error) {
      toast.error(error.message);
      setPromoCode("");
      setpromoData(null);
    } finally {
      // setPromoCode("");
      // setShowPromoCodeField(false);
    }
  };
  return (
    <TemplateMain page={page} navBack={navBack}>
      <div className="co-bottom-wrapper">
        <div className="container_ w-container">
          <div className="co-grid-wrapper">
            {/* <!-- hidden input --> */}
            <input
              type="hidden"
              name="__plan_id"
              id="__plan_id"
              value="monthly_699_All Time Design_team_2022"
            />
            <input type="hidden" name="__coupon" id="__coupon" />
            {/* <!-- content account --> */}
            <div id="__content_account" className="co-grid-left padding-left">
              {page === 1 ? (
                <SelectService />
              ) : (
                <PaymentPage
                  uId={uId}
                  planId={planId}
                  promoData={promoData}
                  isLoading={isLoading}
                  isPlanAvailable={isPlanAvailable}
                  allPlans={allPlans}
                  totalAmmount={totalAmmount}
                />
              )}
            </div>

            <PlanCard
              promoData={promoData}
              isPlanAvailable={isPlanAvailable}
              allPlans={allPlans}
              uId={uId}
              isLoading={isLoading}
              totalAmount={totalAmmount}
              cardPage={page}
              setPage={setPage}
              onAppPromo={onAppPromo}
              coupon={coupon}
              planId={planId}
              setpromoData={setpromoData}
              setPromoCode={setPromoCode}
            />
          </div>
        </div>
      </div>
    </TemplateMain>
  );
};

export default ConfirmPlanDetails;
