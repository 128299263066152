import * as React from "react";

const SvgCustomers = (props) => (
  <svg
    className="Customers_svg__SVGInline-svg Customers_svg__SVGInline--cleaned-svg Customers_svg__SVG-svg Customers_svg__db-SideNav-icon-svg Customers_svg__db-SideNav-icon--customers-svg Customers_svg__wh-16"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="#fff">
      <path
        d="M13.445 13.861C12.413 12.885 10.362 12.22 8 12.22s-4.413.665-5.445 1.641a8 8 0 1 1 10.89 0zM8 9.231a3.077 3.077 0 1 0 0-6.154 3.077 3.077 0 0 0 0 6.154z"
        className="Customers_svg__db-SideNav-iconFill--primary"
      />
      <path
        d="M13.944 13.354A7.98 7.98 0 0 1 8 16a7.98 7.98 0 0 1-5.944-2.646C2.76 12.043 5.154 11.077 8 11.077s5.24.966 5.944 2.277z"
        className="Customers_svg__db-SideNav-iconFill--secondary"
      />
    </g>
  </svg>
);

export default SvgCustomers;
