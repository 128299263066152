/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import {
  closeModal,
  setPaymentCards,
  setCurrentPlan,
  setActivePlanCards,
} from "../../../features";
import tickIcon from "../assets/icon-check-circle-green.svg";
import lockIcon from "../assets/lock.svg";
import { useEffect, useState } from "react";

const PlanModal = ({
  modalContent,
  setSelectedPlan,
  selectedPlan,
  queryParams,
}) => {
  const { isOpenModal, planDetails, currentPlan } = useSelector(
    (store) => store.auth,
  );
  const dispatch = useDispatch();
  const [disableTab, setDisableTab] = useState(false);
  const [trialStatus, setTrialStatus] = useState(false);
  const plan_Id = {
    s_1: "s_1",
    p_1: "p_1",
    pr_1: "pr_1",
    ul_1: "ul_1",
  };
  const couponCode = {
    hbvjikg: "hbvjikg",
  };
  // modals active plan
  const [activePlan, setActivePlan] = useState([]);
  const addons = [
    "Graphic Design",
    "Custom Illustrations",
    "Presentation Designs",
    "Motion Graphics & Animations",
    "Landing Page & UI Designs",
    "Front End Development",
  ];

  useEffect(() => {
    setActivePlan(
      modalContent.reduce((acc, cur) => {
        if (cur.id === selectedPlan) {
          return cur.data.map((item) => {
            return { ...item, period: cur.period };
          });
        }
        return acc;
      }, []),
    );
  }, [modalContent, selectedPlan]);

  useEffect(() => {
    if (
      plan_Id[queryParams.plan_id] === queryParams.plan_id &&
      couponCode[queryParams.deals] === queryParams.deals
    ) {
      setDisableTab(true);
    } else {
      setDisableTab(false);
    }
    if (queryParams?.offers === "off_50") {
      setTrialStatus(true);
    }
  }, [queryParams]);

  useEffect(() => {
    Object.keys(planDetails).length &&
      setSelectedPlan(planDetails?.[currentPlan]?.planType.toLowerCase());
  }, [planDetails]);
  return (
    <div className="checkout-modals">
      <div
        id="__modal_pricing"
        className="co-modal-pricing"
        style={{ display: isOpenModal ? "flex" : "none" }}
      >
        <div
          className="black-overlay"
          onClick={() => dispatch(closeModal())}
        ></div>
        <div className="div-block-183" style={{ fontWeight: "500" }}>
          <div
            className="container_ w-container"
            style={{ maxWidth: "1600px" }}
          >
            <div
              data-easing="ease"
              data-duration-in={0}
              data-duration-out={0}
              id="__duration"
              className="p-tabs-wrapper p-modal w-tabs"
              data-current="Monthly"
            >
              <div className="tabs-menu p-modal w-tab-menu" role="tablist">
                {!disableTab &&
                  modalContent.map((head, ind) => {
                    return (
                      <button
                        key={ind}
                        className="p-tab-button-container w-inline-block w-tab-link "
                        onClick={() => {
                          setSelectedPlan(head.id);
                        }}
                        role="tab"
                        aria-controls="__tab_content_monthly"
                      >
                        <div className="p-tab-comment invisible">&nbsp;</div>
                        <div
                          className={`p-tab-text ${
                            ind === 0
                              ? "radius-top-left"
                              : ind === modalContent.length - 1 &&
                                "radius-top-right"
                          } ${selectedPlan === head.id && "duration-sub"}`}
                        >
                          {head.name}

                          {head.offer !== 0 && (
                            <span className="p-tab-text-small">
                              {" "}
                              ({head.offer}% off)
                            </span>
                          )}
                        </div>
                      </button>
                    );
                  })}
              </div>
              <div className="tabs-content p-modal w-tab-content">
                <div
                  data-w-tab="Monthly"
                  className="w-tab-pane w--tab-active"
                  id="__tab_content_monthly"
                  role="tabpanel"
                  aria-labelledby="__duration_monthly"
                >
                  <div className="tab-content">
                    <div className="w-layout-grid p-packages">
                      {activePlan.map((plan, ind) => {
                        const visible = currentPlan === plan.id;
                        return (
                          <div
                            key={ind}
                            data-w-id="74e4fb26-0f41-25f4-35ae-ea5c5bd74a20"
                            className={`p-package-item-wrapper ${
                              visible && "p-package-item-wrapper-choose"
                            }`}
                            // id="__package_pro_monthly"
                          >
                            <div
                              style={{ backgroundColor: "rgb(242, 244, 246)" }}
                              className="p-packages-item"
                            >
                              <div className="p-package-info-top">
                                <div className="p-packages-main">
                                  <div className="p-package-heading-container">
                                    <div
                                      id="w-node-_74e4fb26-0f41-25f4-35ae-ea5c5bd74a25-a1efd709"
                                      className="p-package-title"
                                    >
                                      {plan.name}
                                    </div>
                                    {plan.name === "Premium" && (
                                      <div className="popular-tag">
                                        Most Popular
                                      </div>
                                    )}
                                  </div>
                                  {plan.actual_price !== plan.offer_price && (
                                    <div className="sm-amt">
                                      ${plan.actual_price}
                                    </div>
                                  )}

                                  {selectedPlan !== "monthly" &&
                                    trialStatus && (
                                      <div
                                        className="sm-amt"
                                        style={{
                                          marginTop: "10px",
                                          textDecoration: "none",
                                        }}
                                      >
                                        $
                                        {plan.offer_price.toLocaleString(
                                          undefined,
                                          { minimumFractionDigits: 2 },
                                        )}{" "}
                                        <span>
                                          /1<sup>st</sup>month
                                        </span>
                                      </div>
                                    )}
                                  <div className="p-package-price-container">
                                    <div className="p-package-price">
                                      $
                                      {trialStatus
                                        ? selectedPlan === "monthly"
                                          ? plan.offer_price.toLocaleString(
                                              undefined,
                                              { minimumFractionDigits: 2 },
                                            )
                                          : plan.next_month.toLocaleString()
                                        : plan.offer_price.toLocaleString()}
                                    </div>
                                    <div className="p-package-frequency">
                                      {trialStatus && selectedPlan === "monthly"
                                        ? "This month"
                                        : "/month"}
                                    </div>
                                  </div>
                                  {(selectedPlan === "quarterly" ||
                                    selectedPlan === "yearly" ||
                                    (selectedPlan === "monthly" &&
                                      trialStatus)) && (
                                    <>
                                      <p className="font__18">
                                        $
                                        {plan.total_offer_price.toLocaleString()}{" "}
                                        billed {selectedPlan}
                                      </p>
                                      {selectedPlan === "monthly" && (
                                        <p className="grn mgb3 mgt1 font__18">
                                          $
                                          {(
                                            plan.total_actual_price -
                                            plan.offer_price
                                          ).toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                          })}{" "}
                                          saved
                                        </p>
                                      )}
                                      {(selectedPlan === "yearly" ||
                                        selectedPlan === "quarterly") && (
                                        <p className="grn mgb3 mgt1 font__18">
                                          $
                                          {(
                                            plan.total_actual_price -
                                            plan.total_offer_price
                                          ).toLocaleString()}{" "}
                                          saved
                                        </p>
                                      )}
                                    </>
                                  )}
                                </div>
                                <button
                                  className="button-large-purple pricing-button modal w-inline-block"
                                  style={{
                                    background: visible && "rgb(44, 44, 44)",
                                    cursor: visible && "default",
                                  }}
                                  onClick={() => {
                                    !visible && dispatch(closeModal());
                                    dispatch(setPaymentCards(plan.name));
                                    dispatch(setCurrentPlan(plan.id));
                                    dispatch(setActivePlanCards(plan.period));
                                  }}
                                >
                                  <span className="button-text-large">
                                    Choose {plan.name}
                                  </span>
                                </button>
                              </div>
                              <div className="divider divider-pricing-item" />
                              <div className="p-package-info-bottom">
                                <div className="p-package-heading">
                                  <div className="p-package-heading-bottom margin-right-0-25">
                                    Services:
                                  </div>
                                </div>
                                <div className="p-package-benefits-list">
                                  {addons.map((item, i) => {
                                    const pickIcon =
                                      i <= 1 ||
                                      (plan.name === "Pro" && i == 2) ||
                                      (plan.name === "Premium" && i <= 3) ||
                                      (plan.name === "Ultimate" && i <= 5);
                                    return (
                                      <div
                                        key={i}
                                        className="p-package-benefits-item"
                                      >
                                        <img
                                          loading="lazy"
                                          src={pickIcon ? tickIcon : lockIcon}
                                          alt=""
                                          className="icon-check-circle-green"
                                        />
                                        <div
                                          className={`p-package-benefits-item-text ${
                                            pickIcon && "blck"
                                          }`}
                                        >
                                          {item}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="ow-modal-button co-modal co-pricing-modal w-embed"
            onClick={() => dispatch(closeModal())}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 24 24"
            >
              <path
                fill="#fff"
                d="m12 13.4l-4.9 4.9q-.275.275-.7.275q-.425 0-.7-.275q-.275-.275-.275-.7q0-.425.275-.7l4.9-4.9l-4.9-4.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.9 4.9l4.9-4.9q.275-.275.7-.275q.425 0 .7.275q.275.275.275.7q0 .425-.275.7L13.4 12l4.9 4.9q.275.275.275.7q0 .425-.275.7q-.275.275-.7.275q-.425 0-.7-.275Z"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanModal;
