import styled from "styled-components";

export const TitleStyle = styled.div`
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  line-height: 28px;
  font-weight: 700;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const Table = styled.table`
  border: 1px solid #e0e1e4;

  & thead {
    background-color: #fafafa;
    border-bottom: 1px solid #e0e1e4;
  }

  & th {
    border-bottom: 0;
    padding-left: 10px;
    font-size: 12px;
  }
`;

export const CardText = styled.span`
  color: #1a1f36;
`;
