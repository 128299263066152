import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Select, Box, CheckBox } from "@atd/components";
import { fetchTemplates } from "@atd/features";

function SelectTemplateField({ value, onChange }) {
  const [isBatch, setIsBatch] = useState(false);

  const { templates } = useSelector((state) => state.template);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTemplates());
  }, [dispatch]);

  const templateOptions = Array.isArray(templates)
    ? templates?.map((template) => ({
        ...template,
        value: template.id,
        label: template.name,
      }))
    : [];

  return (
    <Box
      flex={{
        alignItems: "center",
        direction: "row",
        justifyContent: "flex-start",
        wrap: "nowrap",
      }}
    >
      <Box flex={{ alignItems: "center" }} className="cont2 pd-10">
        <CheckBox
          id="batch"
          checked={isBatch}
          onChange={() => setIsBatch((prevBatch) => !prevBatch)}
        />
        <label htmlFor="batch" style={{ marginLeft: 25 }}>
          Batch
        </label>
      </Box>
      <div id="templ" style={{ minWidth: 300 }}>
        <Select
          value={value}
          size="medium"
          placeholder="Select Template"
          className="Box-root Flex-flex wt-auto"
          options={templateOptions}
          onChange={(templateValue) => {
            const template = templateOptions.find(
              (tmpl) => tmpl.id === templateValue,
            );
            onChange(template ?? templateValue);
          }}
          renderOption={({ label, subject }) => (
            <div className="templatelist">
              {label} <span dangerouslySetInnerHTML={{ __html: subject }} />
            </div>
          )}
        />
        {/* <div className="dropdown wt-100 mgt-22">
          <div className="btn">
            <input
              name="type"
              className="iput customcss6"
              id="TypeSearchBox"
              placeholder="Select Template"
              type="text"
              autoComplete="off"
            />
            <span className="iconn">
              <svg
                aria-hidden="true"
                className="SVGInline-svg SVGInline--cleaned-svg SVG-svg Icon-svg Icon--arrowUpDown-svg Icon-color-svg Icon-color--gray600-svg"
                height={12}
                width={12}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16 16"
              >
                <path d="M11.891 9.992a1 1 0 1 1 1.416 1.415l-4.3 4.3a1 1 0 0 1-1.414 0l-4.3-4.3A1 1 0 0 1 4.71 9.992l3.59 3.591 3.591-3.591zm0-3.984L8.3 2.417 4.709 6.008a1 1 0 0 1-1.416-1.415l4.3-4.3a1 1 0 0 1 1.414 0l4.3 4.3a1 1 0 1 1-1.416 1.415z" />
              </svg>
            </span>
          </div>
          <div className="outer-list">
            <ul>
              <li className="templatelist">
                Sample Template 1{" "}
                <span>
                  SUBJECT:Hi {"{"}
                  {"{"}name{"}"}
                  {"}"}
                </span>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </Box>
  );
}

export default SelectTemplateField;
