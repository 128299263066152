import uuid from "react-uuid";
import { flatMap, map } from "lodash";

import { getCredentialCookies } from "./tokenUtilities";

export const defaultAvatarUrl =
  "https://gravatar.com/avatar/3791b7384194a91cbcd64c2b4cb682d6?s=400&d=mp&r=x";

export const isAdmin = () => {
  const { user_type, token } = getCredentialCookies();

  // make user user is logged in successfully
  if (!token) return false;

  if (user_type && user_type === "admin") {
    return true;
  }
  return false;
};

export const fileName = (name, type) =>
  `${name.substr(0, 5)}...${name.substr(-5)}.${type}`;

export const getNameByFirstChar = (userName, length) => {
  const userNameMatches = userName?.match(/\b(\w)/g); // ['J','S','O','N']
  return userNameMatches?.join("").substr(0, length);
};

export const getErrorMessage = (data) => {
  if (!data) return null;

  const { status, message, results } = data || {};

  let errorMessage;

  if (status === "error") {
    errorMessage = message;
  }

  if (results && results.length > 0) {
    errorMessage = results[0].msg;
  }

  return errorMessage;
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export const getUserGeoLocationDetails = () =>
  fetch("https://ipapi.co/json/")
    .then((res) => res.json())
    .then((data) => {
      return data;
    });

/**
 * @param  {Object} requireContext
 * @return {Object}
 */
export function resolveFile(requireContext) {
  const files = requireContext
    .keys()
    .map((file) => [file.replace(/(^.\/)|(\.svg$)/g, ""), requireContext(file)])
    .reduce((guards, [name, guard]) => ({ ...guards, [name]: guard }), {});
  return files;
}

export const getCurrentTimeZone = () => {
  // Offset hours:
  const offsetHours = (new Date().getTimezoneOffset() / -60) * 60 * 60;
  return offsetHours;
};

export const getClientLocalTime = (offset) => {
  // create Date object for current location
  const d = new Date();

  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  const utc = d.getTime() + d.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  const nd = new Date(utc + 3600000 * (offset / 60 / 60));

  // return time as a string
  return nd.toLocaleString();
};

export const getDimentions = (fieldValue) => {
  const fileDimentionsString = fieldValue?.split(",");
  const unitType = /(px|cm|in|ft|mm)/g;
  const dimentions = fileDimentionsString.reduce((acc, dimention) => {
    // get unit type from the input box and convert to string
    const dimentionType = dimention.toString().match(unitType);
    const dimentionTypeString = dimentionType?.join();
    // check if user type a measurement in the input box
    const hasMeasurement = new RegExp(unitType, "i").test(dimention);
    // const currentDimention = dimention.replace(unitType, "");
    // Convert string to array
    // const dimentionsArray = currentDimention?.split("x");
    // get unit type and set px as default unit
    const unit =
      dimentionTypeString && hasMeasurement
        ? dimentionTypeString.toLowerCase()
        : "px";
    // const [width, height] = dimentionsArray || [];
    // const dimentionWidth = new RegExp("W", "i").test(width)
    //   ? width.trim().replace(/w/i, "") * 1
    //   : width;
    // const dimentionHeight = new RegExp("H", "i").test(height)
    //   ? height.trim().replace(/h/i, "") * 1
    //   : height;
    acc.push({
      height: 0,
      length: 0,
      units: unit,
    });
    return acc;
  }, []);
  return dimentions;
};

export const getAssignToUsersOptions = (users) => {
  if (!users) return [];

  return users.map((user) => ({
    id: uuid(),
    value: user.title,
    options: user?.users?.map((person) => ({
      id: person.id,
      name: person.name,
      value: person.name,
      icon: person.user_icon,
    })),
  }));
};

export const getFormattedTime = ({ hrs, mins, secs }) => {
  const currentHour = hrs < 10 ? `0${hrs}` : hrs || "00";
  const currentMin = mins < 10 ? `0${mins}` : mins || "00";
  const currentSecond = secs < 10 ? `0${secs}` : secs || "00";
  return `${currentHour}:${currentMin}:${currentSecond}`;
};

export const getFilterOptions = (options, label, id) => {
  if (options) {
    return options.map((option) => ({
      label: label ? option[label] : option?.name,
      value: id ? option[id] : option?.id,
    }));
  }
  return [];
};

export const getNotActiveUsers = (users) => {
  const nonActiveUsers = users.filter((people) => people.value !== "Active");
  const disabledUsers = flatMap(nonActiveUsers, ({ options }) =>
    map(options, ({ id: userId }) => userId),
  );
  return disabledUsers;
};

export const getActivityType = (activity) => {
  const types = {
    status_change: getActivityByStatus(activity),
    status_update: "has completed the ticket",
    edited_role: "has changed the user role from",
    assign_user: `has ${
      activity?.assigned_to?.length ? "assigned" : "unassigned"
    } the ticket`,
    reassign_user: "has reassigned the ticket",
    timer_status: `has ${getTimerStatus(activity.timer_status)} the ticket`,
    in_progress: "It is in Progress",
    unarchive: "has unarchived the ticket",
    archive: "has archived the ticket",
    created_notes: "created notes in the ticket",
    edited_notes: "edited notes in the ticket",
    deleted_notes: "deleted notes in the ticket",
    edited_draft: "has edited the draft ticket",
    brand_deleted: "has deleted the ",
    brand_unarchived: "has unarchived the ",
    brand_archived: "has archived the ",
    edited_brand: "has edited the ",
    created_brand: "has created new ",
    delete:
      activity?.user_type === "admin"
        ? "has deleted the ticket"
        : "has deleted draft ticket",
    invited: `has invited new ${activity?.new_value} user with the email id`,
    drafted: "has drafted the ticket",
    duplicated: "has duplicated the ticket",
    replied: "has replied to the ticket",
    logged_in: "has logged in",
    logout: "has logged out",
    exported_client_list: "exported the client list",
    pofile_updated: "has updated the profile",
    edited_profile: "has edited the profile",
    changed_password: "has changed the password",
    password_updated: "has changed the password",
    priority_change: "has changed the ticket",
    creating_announcement: "is creating an announcement",
    created_announcement: "has created an announcement",
    edited_announcement: "has edited an announcement",
    deleted_announcement: "has deleted announcement",
    announcement_activated: "has activated announcement",
    announcement_deactivated: "has deactivated announcement",
    created_notification: "has created a notification",
    edited_notification: "has edited a notification",
    deleted_notification: "has deleted a notification ",
    notification_activated: "has activated the notification",
    notification_deactivated: "has deactivated the notification",
    created_template: "has created a new template",
    edited_template: "has edited template",
    deleted_template: "has deleted template",
    duplicated_template: "has duplicate template",
    plan_upgraded: "has upgraded the plan",
    added_plan: "has added new plan - ",
    upgraded_plan: "has upgraded the plan",
    enabled_invoice: "has enabled invoice option for the client",
    disabled_invoice: "has disabled invoice option for the client",
    enabled_card_update_option: "has enabled card update option for the client",
    disabled_card_update_option:
      "has disabled card update option for the client",
    "upgraded plan": "has upgraded the plan",
    deactivated: "has deactivated",
    activated: "has activated",
  };
  return types[activity.type];
};

export const getTimerStatus = (type) => {
  const status = {
    play: "resumed the timer for",
    pause: "paused the timer for",
    start: "started to play the timer for",
    complete: "completed",
  };
  return status[type];
};
export const getStatus = (type) => {
  const statusTypes = {
    1: "request open",
    2: "draft",
    3: "pending",
    4: "in progress",
    5: "request delivered",
    6: "Request revision",
    7: "Request closed",
  };
  return statusTypes[type];
};

export const getUserName = (users, userId) => {
  return users.find((user) => user.user_id === userId);
};

export const getAssignToUserName = (users, assignedTo) => {
  if (assignedTo) {
    const assignedUsers = assignedTo
      .map((id) => {
        return users.find((user) => user.id === id)?.user_name;
      })
      .filter(Boolean)
      .join(",");
    return Array.isArray(assignedUsers) ? " " : assignedUsers;
  }
  return "";
};

export const getActivityGroup = (filters, activity) => {
  if (!!activity?.group && activity?.new_value && Array.isArray(filters)) {
    let activityGroup = {};
    let new_value = filters.find((val) => val.id === activity.new_value)?.name;
    let old_value = filters.find((val) => val.id === activity.old_value)?.name;
    activityGroup = { ...activityGroup, new_value, old_value };
    return activityGroup ? activityGroup : activity?.new_value;
  } else {
    return activity?.new_value;
  }
};

export const getActivityLog = (activity, users) => {
  const activityLogs = {
    status_change:
      getActivityByStatus(activity) === "has changed the ticket"
        ? `${activity?.request_name} from ${getStatus(
            activity?.old_status,
          )} to ${getStatus(activity?.new_status)}`
        : `${activity?.request_name}`,
    status_update: `${activity?.request_name}`,
    edited_role: `from ${activity?.old_value} to ${activity?.new_value} for the maild id ${activity?.email}`,
    plan_upgraded: `${activity?.request_name} from ${activity?.old_value} to ${activity?.new_value}`,
    assign_user: activity?.assigned_to?.length
      ? `${activity?.request_name} to ${users}`
      : "",
    reassign_user: `${activity?.request_name} to ${users || "None"}`,
    creating_announcement: `named ${activity?.new_value}`,
    created_announcement: `named ${activity?.new_value}`,
    deleted_announcement: `${activity?.new_value}`,
    edited_announcement: `${activity?.new_value}`,
    announcement_activated: `${activity?.new_value}`,
    announcement_deactivated: `${activity?.new_value}`,
    deleted_notification: `${activity?.new_value}`,
    created_notification: `${activity?.new_value}`,
    edited_notification: `${activity?.new_value}`,
    notification_activated: `${activity?.new_value}`,
    notification_deactivated: `${activity?.new_value}`,
    created_template: `named ${activity?.new_value}`,
    edited_template: `${activity?.new_value}`,
    deleted_template: `${activity?.new_value}`,
    duplicated_template: `${activity?.new_value}`,
    added_plan: `${activity?.new_value}`,
    upgraded_plan: `from ${activity?.old_value} to ${activity?.new_value}`,
    "upgraded plan": `from ${activity?.old_value} to ${activity?.new_value}`,
    invited: `${activity?.email}`,
    priority_change: `priority from ${activity?.old_value} to ${activity?.new_value}`,
    timer_status: `${activity?.request_name}`,
    unarchive: `${activity?.request_name}`,
    archive: `${activity?.request_name}`,
    replied: `${activity?.request_name}`,
    duplicated: `${activity?.request_name}`,
    created_notes: `${activity?.request_name}`,
    edited_notes: `${activity?.request_name}`,
    deleted_notes: `${activity?.request_name}`,
    edited_draft: `${activity?.request_name}`,
    enabled_invoice: `${activity?.company_name}`,
    disabled_invoice: `${activity?.company_name}`,
    enabled_card_update_option: `${activity?.company_name}`,
    disabled_card_update_option: `${activity?.company_name}`,
    created_brand: `${activity?.new_value} brand`,
    edited_brand: `${activity?.new_value} brand`,
    brand_archived: `${activity?.new_value} brand`,
    brand_unarchived: `${activity?.new_value} brand`,
    brand_deleted: `${activity?.new_value} brand`,
    delete: activity?.user_type === "admin" ? `${activity?.request_name}` : "",
    deactivated: `${activity?.new_value} user with the mail ID ${activity?.email}`,
    activated: `${activity?.new_value} user with the mail ID ${activity?.email}`,
  };
  return activityLogs[activity.type];
};

export const getActivityByStatus = (activity) => {
  const status = `${activity?.old_status}-${activity?.new_status}`;
  const activityStatus = {
    "2-1": "has raised the ticket",
    "1-7": "has completed the ticket",
    "2-7": "has completed the ticket",
    "3-7": "has completed the ticket",
    "4-7": "has completed the ticket",
    "5-7": "has completed the ticket",
    "6-7": "has completed the ticket",
  };
  return activityStatus[status]
    ? activityStatus[status]
    : "has changed the ticket";
};

export const getUniqueObjects = (json) => {
  let jsonObject = json.map(JSON.stringify);
  const uniqueSet = new Set(jsonObject);
  return Array.from(uniqueSet).map(JSON.parse);
};

export const getCreatedDate = (val, customersFilter) => {
  const created = customersFilter[val].created?.split(" ")?.join("_");
  let createdDate = {};
  if (created === "is_in_the_last") {
    createdDate = {
      created_date: Object.values(customersFilter[val])?.length
        ? `${created}:${customersFilter[val].time_period},${customersFilter[val].period}`
        : undefined,
    };
  } else if (created === "is_between") {
  } else {
    createdDate = {
      created_date: Object.values(customersFilter[val])?.length
        ? `${created}:${customersFilter[val].time_period}`
        : undefined,
    };
  }

  return createdDate;
};

export const calculateExpireDate = (trial_date) => {
  const d = new Date(trial_date * 1000);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const year = d.getFullYear();
  const month = months[d.getMonth()];
  const date = d.getDate();

  const time = month + " " + date + "," + year + " ";
  return time;
};

// export const epochConvert = ({ ...request }) => {
//   const trial_start = new Date();
//   const trial_end = new Date(request.trial_end * 1000);
//   const trial_end_year = trial_end.getFullYear();
//   const trial_end_month_date = trial_end.getDate();
//   const trial_start_date =
//     trial_start.getDate() +
//     "/" +
//     (trial_start.getMonth() + 1) +
//     "/" +
//     trial_start.getFullYear();

//   const trial_end_date =
//     trial_end_month_date +
//     "/" +
//     (trial_end.getMonth() + 1) +
//     "/" +
//     trial_end_year;
//   const trial_status = trial_start_date <= trial_end_date ? true : false;
//   return trial_status;
// };
