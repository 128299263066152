import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, TabList, TabPanel } from "react-tabs";

import { Box, Button, Alert, AppHeader } from "@atd/components";
import { Main } from "@atd/layouts";
import { isAdmin } from "@atd/utils";
import { fetchTeams } from "@atd/features";

import ThreadList from "./ThreadList";
import DesignFiles from "./DesignFiles";
import ProductionFiles from "./ProductionFiles";
import RequestInfo from "./RequestInfo";
import UITabs from "../../Tabs";

const DETAILS_TAB = [
  { id: "details", value: "Details" },
  { id: "production_files", value: "Design Files" },
  { id: "design_files", value: "Production Files" },
];

function LeftContent({ children }) {
  return (
    <Box
      padding={{
        right: 20,
        left: 0,
        vertical: 20,
      }}
      divider={{
        color: "light",
        side: "right",
        width: 1,
      }}
      className="flx6"
    >
      {children}
    </Box>
  );
}

function RequestThread() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useDispatch();
  const { request } = useSelector((state) => state.requests);
  const { user } = useSelector((state) => state.user);
  const { teams } = useSelector((state) => state.team);

  const { s_no, status, request_name, subscription_paused } = request || {};

  useEffect(() => {
    dispatch(fetchTeams({ type: "accepted", dropdown: "yes", delete: "yes" }));
  }, [dispatch]);

  const renderRequestDetail = () => (
    <RequestInfo {...request} users={teams} role={user?.role} />
  );

  return (
    <Main>
      {!!subscription_paused && (
        <Alert
          status="error"
          title="Subscription Paused"
          icon="warning"
          withIcon
          description={<span>{subscription_paused}</span>}
        />
      )}
      <AppHeader
        hasTabs={isAdmin() ? true : false}
        title={request_name && s_no ? `(#${s_no}) ${request_name}` : ""}
        spread="appContent"
        marginBottom={isAdmin() ? true : false}
      >
        <Button
          variant="default"
          label="Back to Requests"
          icon="left-arrow"
          onClick={() => navigate(-1)}
        />
      </AppHeader>
      {isAdmin() && (
        <UITabs
          selectedTabClassName="active"
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
          }}
        >
          <TabList className="tabs Padding-all--0 Margin-bottom--0">
            {DETAILS_TAB.map((tab) => (
              <Tab key={tab.id} className="tab-item capitalize">
                {tab.value}
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <Box flex={{ direction: "row" }}>
              <LeftContent>
                <Box>
                  <ThreadList
                    id={id}
                    request={request}
                    status={status}
                    isSubscriptionPaused={!!subscription_paused}
                  />
                </Box>
              </LeftContent>
              {renderRequestDetail()}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box flex={{ direction: "row" }}>
              <LeftContent>
                <DesignFiles id={id} />
              </LeftContent>
              {renderRequestDetail()}
            </Box>
          </TabPanel>
          <TabPanel>
            <Box flex={{ direction: "row" }}>
              <LeftContent>
                <ProductionFiles id={id} />
              </LeftContent>
              {renderRequestDetail()}
            </Box>
          </TabPanel>
        </UITabs>
      )}
      {!isAdmin() && (
        <Box flex={{ direction: "row" }}>
          <Box
            padding={{
              right: 20,
              left: 0,
              vertical: 20,
            }}
            divider={{
              color: "light",
              side: "right",
              width: 1,
            }}
            className="flx6"
          >
            <Box>
              <ThreadList id={id} request={request} status={status} />
            </Box>
          </Box>
          {renderRequestDetail()}
        </Box>
      )}
    </Main>
  );
}

export default RequestThread;
