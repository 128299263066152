import { useRef } from "react";
import { useFormikContext } from "formik";
import moment from "moment";
import { useDispatch } from "react-redux";

import { Box, Button, Tooltip } from "@atd/components";

import RequestService from "@atd/features/request/requestApi";
import { toast } from "react-toastify";
import { deleteNote } from "@atd/features";
import Attachment, { Attachments } from "../Attachments";
import CreateNote from "./CreateNote";
import { withSelector } from "@atd/hoc";

function Note(props) {
  const {
    id,
    user,
    index,
    versions,
    request_id,
    from_company,
    user_name,
    created,
    body,
    attachments,
    activity,
    isNew,
    isEditing,
    request,
    onCacel,
    onRemove,
  } = props;
  const toastDeletingId = useRef(null);

  const { setFieldValue } = useFormikContext();

  const dispatch = useDispatch();

  const { status: ticketStatus } = request || {};
  const { role } = user || {};

  const onDeleteNote = async () => {
    // eslint-disable-next-line no-alert
    if (window.confirm("Are you sure you want to delete this note?")) {
      // eslint-disable-next-line no-multi-assign
      const notify = (toastDeletingId.current = toast.info("Deleting note..."));
      await RequestService.deleteNote(request_id, id);
      await dispatch(deleteNote(id));
      notify.dismiss();
      onRemove();
    }
  };

  if (isNew || isEditing) {
    return (
      <Box flex margin={{ bottom: 16 }}>
        {/* <ActivityIcon backgroundColor="#ABDDED">N</ActivityIcon> */}
        <CreateNote
          index={index}
          noteId={id}
          versions={versions}
          activity={activity}
          attachments={attachments}
          isEditing={isEditing}
          onCacel={onCacel}
        />
      </Box>
    );
  }

  return (
    <Box flex>
      {/* <ActivityIcon backgroundColor="#ABDDED">
        {getNameByFirstChar(user_name || from_company)}
      </ActivityIcon> */}
      <Box
        margin={{ bottom: 16 }}
        className="blue-box orange-box"
        style={{ flex: 1 }}
      >
        <table className="table mb-0 wt-100 " id="teams">
          <tbody>
            <tr>
              <td className="title2">{user_name || from_company}</td>
              <td className="right">
                {ticketStatus !== 7 && (
                  <>
                    <Tooltip content="Delete Note">
                      <Button
                        iconColor="black"
                        variant="link"
                        icon="trash"
                        onClick={onDeleteNote}
                        style={{ color: "#000" }}
                      />
                    </Tooltip>
                    <Tooltip content="Edit Note">
                      <Button
                        iconColor="black"
                        variant="link"
                        icon="pencil"
                        onClick={() =>
                          setFieldValue(`threads.${index}.isEditing`, true)
                        }
                        style={{ color: "#000" }}
                      />
                    </Tooltip>
                  </>
                )}
                {moment(created).format("DD/MM/YYYY hh:mm A")}
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <div dangerouslySetInnerHTML={{ __html: body }} />
                {attachments?.length > 0 && (
                  <Attachments>
                    {attachments.map((file) => (
                      <Attachment
                        key={file.link}
                        file={file}
                        isDeletable={false}
                        onClick={() => {
                          if (role !== 4) {
                            window.open(file.link);
                          }
                        }}
                      />
                    ))}
                  </Attachments>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Box>
    </Box>
  );
}

export default withSelector((state) => state.user)(Note);
