import { toast } from "react-toastify";

import { axios, getCurrentTimeZone } from "@atd/utils";

class DeliveryService {
  async add(id, payload) {
    try {
      const { data } = await axios.post(`/deliver/${id}`, payload, {
        params: {
          time_zone: getCurrentTimeZone(),
        },
      });

      if (data.status === "success") {
        toast.success(data.message);
        return data;
      }
      // display error message
      if (data.results && data.results.length > 0) {
        data.results.forEach((item) => {
          toast.error(`${data.message} : ${item.msg}`);
        });
      } else {
        toast.error(data.message);
      }

      throw new Error(data.message);
    } catch (err) {
      throw new Error(err);
    }
  }
}

export default new DeliveryService();
