import * as React from "react";

const SvgReports = (props) => (
  <svg
    className="Reports_svg__SVGInline-svg Reports_svg__SVGInline--cleaned-svg Reports_svg__SVG-svg Reports_svg__db-SideNav-icon-svg Reports_svg__db-SideNav-icon--reports-svg Reports_svg__wh-16"
    height={16}
    width={16}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="none">
      <path
        d="M2 9h1a1 1 0 1 0 0-2H2V5h1a1 1 0 1 0 0-2H2V2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-1h1a1 1 0 0 0 0-2H2z"
        fill="#fff"
        className="Reports_svg__db-SideNav-iconFill--secondary"
      />
      <path
        d="M6 10h2v3H6zm3-3h2v6H9zm3-4h2v10h-2zM0 12a1 1 0 0 1 1-1h1v2H1a1 1 0 0 1-1-1zm0-4a1 1 0 0 1 1-1h1v2H1a1 1 0 0 1-1-1zm0-4a1 1 0 0 1 1-1h1v2H1a1 1 0 0 1-1-1z"
        fill="#000"
        className="Reports_svg__db-SideNav-iconFill--primary"
      />
    </g>
  </svg>
);

export default SvgReports;
