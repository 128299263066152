/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field } from "formik";

import { ContentState, Tooltip } from "@atd/components";

import AssociateBrandCreate from "./AssociateBrandCreate";

import BrandFolder from "../../../../../assets/images/brand-folder.png";

function AssociateBrand({
  brands,
  loading,
  requestId,
  onChange,
  onSetFieldValue,
}) {
  return (
    <>
      <div className="title2 mgt4">Associate Brand</div>
      <div className="cont4">
        Add any files that you&rsquo;d like your designer to use for your
        design.
      </div>
      <div className="FormField mgt1" id="brand">
        {/* Checkbox */}
        <div className="checkbox-group">
          <div className="checkbox">
            <label htmlFor="noBrandId" className="checkbox-wrapper">
              <Field
                id="noBrandId"
                type="radio"
                name="brand_id"
                value=""
                className="checkbox-input"
                onChange={onChange}
              />
              <span className="checkbox-tile">
                <span className="checkbox-label">
                  <img src={BrandFolder} alt="ATD" className="va1" />{" "}
                  <span className="va2">No brand</span>
                </span>
              </span>
            </label>
          </div>
          {loading && <ContentState />}
          {!loading &&
            brands?.length > 0 &&
            brands.map((brand) => (
              <div key={brand.id} className="checkbox">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor={brand.id} className="checkbox-wrapper">
                  <Field
                    id={brand.id}
                    name="brand_id"
                    type="radio"
                    className="checkbox-input"
                    value={brand.id}
                    onChange={onChange}
                  />
                  <span className="checkbox-tile">
                    <Tooltip content={brand.brand_name}>
                      <span className="checkbox-label wt-90 ellipsis">
                        <img
                          src={BrandFolder}
                          alt={brand.brand_name}
                          className="va1"
                        />{" "}
                        <span className="va2">{brand.brand_name}</span>
                      </span>
                    </Tooltip>
                  </span>
                </label>
              </div>
            ))}
          <AssociateBrandCreate
            requestId={requestId}
            onSetFieldValue={onSetFieldValue}
          />
        </div>
        {/* Checkbox end */}
      </div>
    </>
  );
}

export default AssociateBrand;
