import { useDispatch } from "react-redux";
import { Link, useSearchParams, useNavigate, Navigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { omit } from "lodash";

import { resetPassword } from "@atd/features";

import { AuthSchema } from "@atd/schema";
import Auth from "./Auth";

function AuthReset() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const email = searchParams.get("email");

  const uId = searchParams.get("u_id");

  const dispatch = useDispatch();

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const newValues = omit(values, "new_password");
      const response = await dispatch(resetPassword(newValues)).unwrap();
      if (response) {
        navigate("/login");
      }
    } catch (error) {
      setSubmitting(false);
    } finally {
      resetForm({});
      setSubmitting(false);
    }
  };

  const initialValues = {
    email,
    u_id: uId,
    password: "",
  };

  if (!uId || !email) {
    return <Navigate to="/login" />;
  }

  return (
    <Auth title="Reset your password">
      <Formik
        initialValues={initialValues}
        validationSchema={AuthSchema}
        onSubmit={onSubmit}
        // validateOnBlur={false}
        validateOnChange={false}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form>
            <Field
              readOnly
              type="email"
              name="email"
              placeholder="Email Address"
            />
            <Field
              type="password"
              name="new_password"
              placeholder="Password"
              className={
                errors.password &&
                touched.password &&
                "FormField__input--hasError"
              }
            />
            <Field
              type="password"
              name="password"
              placeholder="Confirm Password"
              className={
                errors.password &&
                touched.password &&
                "FormField__input--hasError"
              }
            />
            {errors.password && touched.password ? (
              <div
                className="FormField__input--error"
                style={{ marginBottom: 20, marginTop: 0, textAlign: "left" }}
              >
                {errors.password}
              </div>
            ) : null}
            <button disabled={isSubmitting} type="submit">
              Reset Password
            </button>
            <p>
              <Link to="/login">Back to Sign in</Link>
            </p>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default AuthReset;
