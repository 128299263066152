/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
// import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
// import styled from "styled-components";

import {
  fetchTeams,
  getAllRequestAsync,
  searchRequest,
  getRequestsCount,
} from "@atd/features";
import { isAdmin, getCredentialCookies } from "@atd/utils";
import {
  Box,
  ContentState,
  AppHeader,
  Button,
  Tabs,
  Tab,
} from "@atd/components";
import { useLocalStorage } from "@atd/hooks";

import AdminTabContent from "./TabContent/AdminTabContent";
import ClientTabContent from "./TabContent/ClientTabContent";

import RequestFilters from "./RequestFilters";
import { tabList } from "./RequestTabOptions";

// const RequestsTab = styled(TabList)`
//   @media (max-width: 1024px) {
//     min-width: 1095px;
//   }

//   .tab-item {
//     @media (max-width: 1024px) {
//       margin-right: 15px;
//     }
//   }
// `;

function RequestsList({
  page,
  role,
  loading,
  params,
  isSearch,
  headerText,
  loaderRef,
  setIsResetData,
  onResetData,
  showFilters = true,
  inifiniteScroll = true,
  onAddNewDesignRequest,
  renderAboveTitle,
}) {
  const { query } = useParams();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(null);

  const { user_id } = getCredentialCookies();
  const { user_type } = getCredentialCookies();

  const [requestFilters, setRequestFilters] = useLocalStorage(
    `${user_id}-requests-filters`,
    {
      ...params,
      status: isAdmin() ? "new" : "queue",
      page: 1,
    },
  );

  let hasCustomParams = params && Object.keys(params).length > 0;
  useEffect(() => {
    hasCustomParams = params && Object.keys(params).length > 0;
    const findTabIndex = tabList.find((t) => t.id === requestFilters.status);

    console.log("requestFilters", requestFilters, findTabIndex, params);
    setActiveTab(
      !findTabIndex || hasCustomParams ? params?.status : findTabIndex.id,
    );
  }, []);
  // const [activeTabIndex, setActiveTabIndex] = useState(
  //   findTabIndex === -1 || hasCustomParams ? 0 : findTabIndex,
  // );
  const [queryParams, setQueryParams] = useState(
    isAdmin()
      ? hasCustomParams
        ? params
        : requestFilters
      : { status: requestFilters?.status },
  );

  const { fetching, error, hasError, hasMore, requests, counts } = useSelector(
    (state) => state.requests,
  );
  const { teams } = useSelector((state) => state.team);
  const dispatch = useDispatch();

  useEffect(() => {
    const realPage = page + 1;
    const promise = query
      ? dispatch(
          searchRequest({
            ...queryParams,
            search: query,
            page: inifiniteScroll ? realPage : 1,
          }),
        )
      : dispatch(
          getAllRequestAsync({
            ...queryParams,
            page: inifiniteScroll ? realPage : 1,
          }),
        );

    promise.then(() => {
      setIsResetData(false);
      dispatch(
        getRequestsCount({
          ...queryParams,
        }),
      );
    });
    return () => {
      promise.abort();
    };
  }, [page, dispatch, queryParams, inifiniteScroll, setIsResetData, query]);

  useEffect(() => {
    dispatch(
      fetchTeams({
        type: "accepted",
        dropdown: "yes",
        delete: "yes",
      }),
    );
  }, [dispatch]);

  const onChangeFilter = (field, value) => {
    setIsResetData(true);
    onResetData();
    if (field === "group_by" && !["company", "designer"].includes(value)) {
      setQueryParams((prevParams) => ({
        ...prevParams,
        company_id: null,
        assigned_to: null,
        [field]: value,
      }));
    } else {
      setQueryParams((prevParams) => ({
        ...prevParams,
        [field]: value,
      }));
    }

    if (isAdmin() && !hasCustomParams) {
      setRequestFilters((prevParams) => ({
        ...prevParams,
        [field]: value,
      }));
    }
    // dispatch(getRequestsCount());
  };

  const onResetFilters = () => {
    setIsResetData(true);
    onResetData();
    if (isAdmin() && !hasCustomParams) {
      setActiveTab("queue");
      setRequestFilters({
        status: isAdmin() ? "new" : "queue",
        page: 1,
      });
    }

    if (user_type === "admin" || "client") {
      setActiveTab(isAdmin() ? "new" : "queue");
    }

    setQueryParams({
      page: 1,
      status: isAdmin() ? "new" : "queue",
    });
  };

  const onClickTabItem = (value) => {
    onResetData();
    setActiveTab(value);
    setQueryParams({
      ...queryParams,
      filter_status: null,
      status: value,
    });
    if (hasCustomParams) {
      return;
    }
    if (isAdmin()) {
      setRequestFilters({
        ...queryParams,
        filter_status: null,
        status: value,
      });
    } else {
      setRequestFilters({
        status: value,
      });
    }
  };

  const isGroupBy = !!queryParams?.group_by;
  const hasErrors = hasError && error?.message !== "Aborted";

  const renderClientContent = (showPriority) => {
    if (isAdmin()) return null;

    return (
      <>
        <ClientTabContent
          data={requests}
          error={error}
          hasError={hasError}
          isGroupBy={isGroupBy}
          fetching={fetching}
          activeTabStatus={queryParams.status}
          showPriority={showPriority}
        />
        {(hasMore || fetching) && !hasErrors && (
          <Box ref={loaderRef}>
            <ContentState text="Loading..." />
          </Box>
        )}
      </>
    );
  };

  const renderAdminContent = (options = {}) => {
    if (!isAdmin()) return null;

    const { hidePlan, hideAssignTo, showRating, showPriority } = options;

    return (
      <>
        <AdminTabContent
          data={requests}
          error={error}
          hasError={hasError}
          isGroupBy={isGroupBy}
          activeTabStatus={queryParams.status}
          fetching={fetching}
          users={teams}
          hidePlan={hidePlan ?? false}
          hideAssignTo={hideAssignTo ?? false}
          showRating={showRating ?? false}
          showPriority={showPriority}
        />
        {(hasMore || fetching) && !hasErrors && (
          <Box ref={loaderRef}>
            <ContentState text="Loading..." />
          </Box>
        )}
      </>
    );
  };

  const adminTabContentOptions = {
    hidePlan: true,
  };

  if (isSearch && isAdmin()) {
    return renderAdminContent(adminTabContentOptions);
  }

  if (isSearch && !isAdmin()) {
    return renderClientContent();
  }

  // const showStatus =
  //   activeTabIndex === 6 || activeTabIndex === 7 ? true : false;
  return (
    <>
      {location?.pathname === `/clients/${params?.company_id}` ? (
        <Tabs activeTab={activeTab} setActiveTab={onClickTabItem}>
          {tabList.map((item) => (
            <Tab
              key={item.id}
              tabTitle={item.value}
              tabSlug={item.id}
              className="tab-item capitalize"
              count={counts?.[item.id] && isAdmin() ? counts[item.id] : null}
            />
          ))}
        </Tabs>
      ) : (
        <AppHeader
          title={headerText}
          spread="appContent"
          marginBottom={false}
          tabs={
            <Tabs activeTab={activeTab} setActiveTab={onClickTabItem}>
              {tabList.map((item) => (
                <Tab
                  key={item.id}
                  tabTitle={item.value}
                  tabSlug={item.id}
                  className="tab-item capitalize"
                  count={
                    counts?.[item.id] && isAdmin()
                      ? counts[item.id]
                      : counts[item.id]
                  }
                />
              ))}
            </Tabs>
          }
          renderAboveTitle={renderAboveTitle}
        >
          {!isAdmin() && role !== 4 && (
            <Button
              icon="plus"
              iconColor="white"
              disabled={loading}
              label="New Design Request"
              className="request-btn"
              onClick={onAddNewDesignRequest}
            />
          )}
        </AppHeader>
      )}
      {showFilters && (
        <RequestFilters
          params={queryParams}
          setQueryParams={setQueryParams}
          onResetFilters={onResetFilters}
          onChangeFilter={onChangeFilter}
          // showStatus={showStatus}
        />
      )}
      {!isAdmin() && (
        <div className="tab-content">
          {activeTab === "queue"
            ? renderClientContent(true)
            : renderClientContent()}
        </div>
      )}
      {isAdmin() && (
        <>
          {activeTab === "new"
            ? renderAdminContent()
            : activeTab === "closed"
            ? renderAdminContent({
                ...adminTabContentOptions,
                showPriority: false,
                showRating: true,
              })
            : renderAdminContent(adminTabContentOptions)}
        </>
      )}
    </>
  );
}

export default RequestsList;
