import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { Icon } from "@atd/components";
import { isAdmin } from "@atd/utils";

import { Customers, Payments, Settings } from "../../components/Icon/icons";

// import Logo from "./Logo";
// import Menu from "./Menu";
// import Profile from "./Profile";

import Sidebar from "./Sidebar";

import "./Sidebar.css";

function MainSidebar() {
  const { pathname } = useLocation();

  const { user: currentUser } = useSelector((state) => state.user);
  const { role } = currentUser || {};

  const reportsChildrens = [
    // {
    //   to: "/reports/feedback",
    //   name: "Customer",
    // },
    {
      to: "/reports/team",
      name: "Team",
    },
    // {
    //   to: "/reports/sales",
    //   name: "Sales",
    // },
  ];

  // Client Sidebar Menus
  const clientMenus = [
    {
      to: "/requests",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M14.2222222,0 L1.76888889,0 C0.782222222,0 0.00888888889,0.791111111 0.00888888889,1.77777778 L0,14.2222222 C0,15.2 0.782222222,16 1.76888889,16 L14.2222222,16 C15.2,16 16,15.2 16,14.2222222 L16,1.77777778 C16,0.791111111 15.2,0 14.2222222,0 Z M14.2222222,10.6666667 L10.6666667,10.6666667 C10.6666667,12.1422222 9.46666667,13.3333333 8,13.3333333 C6.53333333,13.3333333 5.33333333,12.1422222 5.33333333,10.6666667 L1.76888889,10.6666667 L1.76888889,1.77777778 L14.2222222,1.77777778 L14.2222222,10.6666667 Z"
          />
        </svg>
      ),
      name: "Requests",
      isAccessible: true,
    },
    {
      to: "/brands",
      icon: <Payments />,
      name: "Brands",
      isAccessible: true,
    },
    {
      to: "/teams",
      icon: <Customers />,
      name: "Teams",
      isAccessible: true,
    },
    {
      to: "/settings/account",
      icon: <Settings />,
      name: "Settings",
      isAccessible: true,
    },
  ];
  // Admin Sidebar Menus
  const adminMenus = [
    {
      to: "/requests",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M14.2222222,0 L1.76888889,0 C0.782222222,0 0.00888888889,0.791111111 0.00888888889,1.77777778 L0,14.2222222 C0,15.2 0.782222222,16 1.76888889,16 L14.2222222,16 C15.2,16 16,15.2 16,14.2222222 L16,1.77777778 C16,0.791111111 15.2,0 14.2222222,0 Z M14.2222222,10.6666667 L10.6666667,10.6666667 C10.6666667,12.1422222 9.46666667,13.3333333 8,13.3333333 C6.53333333,13.3333333 5.33333333,12.1422222 5.33333333,10.6666667 L1.76888889,10.6666667 L1.76888889,1.77777778 L14.2222222,1.77777778 L14.2222222,10.6666667 Z"
          />
        </svg>
      ),
      name: "All Requests",
      isAccessible: true,
    },
    {
      to: "/clients",
      icon: <Icon icon="client" />,
      name: "Customer",
      restricted: isAdmin() && [6, 7].includes(role),
      isAccessible: true,
    },
    {
      to: "/activity",
      icon: <Customers />,
      name: "Activity Log",
      restricted: isAdmin() && [2, 6, 7].includes(role),
      isAccessible: true,
    },
    {
      to: "/settings/account",
      icon: <Settings />,
      name: "Settings",
      isAccessible: true,
    },
    {
      to: "/reports/team",
      icon: <Icon icon="reports" />,
      name: "Reports",
      isAccessible: false,
      isActive: reportsChildrens.map(({ to }) => to).includes(pathname),
      restricted: role !== 1,
      children: reportsChildrens,
    },
  ];

  const activeMenus = isAdmin() ? adminMenus : clientMenus;

  return <Sidebar items={activeMenus} />;

  // return (
  //   <div className="LeftNav_wrapper">
  //     <div className="LeftNav">
  //       {/* <Logo /> */}
  //       <Profile />
  //       <nav className="menu">
  //         <ul className="menuList">
  //           {activeMenus.length > 0 &&
  //             activeMenus.map((menuItem) => {
  //               return <Menu key={menuItem.name} {...menuItem} />;
  //             })}
  //         </ul>
  //       </nav>
  //     </div>
  //   </div>
  // );
}

export default MainSidebar;
