import React, { useState, useContext } from "react";

import Popover from "../Popover/AtdPopover";
import Menu, { MenuItem } from "../Menu/Menu";

const DropdownContext = React.createContext({
  onChange: () => {},
});

function DropdownOption({ value, onClick, ...rest }) {
  const { onChange } = useContext(DropdownContext);
  return (
    <MenuItem
      {...rest}
      text={{ weight: "medium" }}
      onClick={(event) =>
        onClick ? onClick(value, event) : onChange(value, event)
      }
    />
  );
}

function Dropdown({
  as: Component,
  onChange,
  render,
  children,
  popover = {
    placement: "bottom-start",
  },
  ...rest
}) {
  const [isShow, setIsShow] = useState(false);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <DropdownContext.Provider value={{ onChange }}>
      <Popover
        visible={isShow}
        offset={[0, 0]}
        element={children}
        onShow={setIsShow}
        {...popover}
      >
        <Component {...rest} />
      </Popover>
    </DropdownContext.Provider>
  );
}

Dropdown.Options = Menu;
Dropdown.Option = DropdownOption;

export default Dropdown;
