import styled from "styled-components";

import Box from "../Box/Box";

const PressableCoreBase = styled(Box)`
  position: relative;
  z-index: 1;
  display: inherit;
  align-items: inherit;
  width: inherit;
  height: inherit;
  border-radius: inherit;
`;

export default PressableCoreBase;
