/* eslint-disable import/extensions */
import { useMemo, useRef, useEffect } from "react";
import classnames from "classnames";

// Require Editor JS files.
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";

// Require Font Awesome.
// import "font-awesome/css/font-awesome.css";

import FroalaEditor from "react-froala-wysiwyg";
import Froala from "froala-editor";

import { getCredentialCookies } from "@atd/utils";

// Include special components if required.
// import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
// import FroalaEditorA from 'react-froala-wysiwyg/FroalaEditorA';
// import FroalaEditorButton from 'react-froala-wysiwyg/FroalaEditorButton';
// import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';
// import FroalaEditorInput from 'react-froala-wysiwyg/FroalaEditorInput';

Froala.DefineIconTemplate(
  "font_awesome_5s",
  '<i class="far fa-[FA5NAME]" aria-hidden="true"></i>',
);

// Froala.DefineIcon("swatchbook", {
//   FA5NAME: "swatchbook",
//   template: "font_awesome_5s",
// });

function Editor(props) {
  const editorRef = useRef(null);

  const { token } = getCredentialCookies();

  const {
    disabled,
    requestId,
    config,
    hasCustomBottomToolbar,
    placeholder,
    className,
  } = props;

  useEffect(() => {
    if (disabled) {
      editorRef.current.editor.edit.off();
    }
  }, [disabled]);

  const classes = classnames("atd-froala-editor", className, {
    "atd-froala-editor--hasCustomBottomToolbar": hasCustomBottomToolbar,
  });

  const toolbarButtons = useMemo(
    () => [
      "bold",
      "italic",
      "underline",
      "subscript",
      "superscript",
      "formatOL",
      "formatUL",
      "clearFormatting",
      "insertTable",
      "insertLink",
      "insertImage",
      "html",
    ],
    [],
  );

  Froala.RegisterCommand("swatchbook", {
    title: "Email Template",
    icons: "swatchbook",
    focus: true,
    undo: true,
    refreshAfterCallback: false,
  });

  const editorConfig = {
    key: process.env.REACT_APP_FROALA_ACTIVATION_KEY,
    attribution: false,
    placeholderText: placeholder,
    heightMin: 115,
    heightMax: 600,
    toolbarSticky: false,
    // enter: Froala.ENTER_BR,
    linkList: [
      {
        text: "Google",
        href: "https://google.com",
        target: "_blank",
      },
      {
        text: "Facebook",
        href: "https://facebook.com",
      },
    ],
    toolbarButtons,
    pluginsEnabled: [
      "link",
      "lists",
      "image",
      "url",
      "table",
      "codeView",
      "codeBeautifier",
    ],
    charCounterCount: false,
    imageDefaultWidth: 0,
    imageDefaultAlign: "left",
    imageUploadParam: "attach",
    imageUploadURL: `${process.env.REACT_APP_API_URL}/froala_attach`,
    requestHeaders: {
      Authorization: `Bearer ${token}`,
    },
    imageUploadParams: { request_id: requestId },
    imageUploadMethod: "POST",
    // imageMaxSize: 5 * 1024 * 1024,
    imageAllowedTypes: ["jpeg", "jpg", "png"],
    editorClass: classes,
    events: {
      initialized() {
        if (disabled) {
          // eslint-disable-next-line react/no-this-in-sfc
          this.edit.off();
        }
      },
    },
    ...config,
  };

  return (
    <FroalaEditor
      {...props}
      ref={editorRef}
      tag="textarea"
      config={editorConfig}
    />
  );
}

export default Editor;
