import { toast } from "react-toastify";

import { axios, getCurrentTimeZone, getErrorMessage } from "@atd/utils";

class ReplyService {
  async add(id, payload) {
    const { data } = await axios.post(`/reply/${id}`, payload, {
      params: {
        time_zone: getCurrentTimeZone(),
      },
    });

    if (data.status === "error") {
      const errorMessage = getErrorMessage(data);
      toast.error(errorMessage);
      throw data;
    }

    if (data.status === "success") {
      toast.success(data.message);
    }

    return data;
  }
}

export default new ReplyService();
