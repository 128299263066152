import axios from "@atd/app/axiosClient";
import { toast } from "react-toastify";
import { getErrorMessage } from "@atd/utils";

class BrandService {
  async getAllBrands(params) {
    const { data } = await axios.get("/brands", {
      params,
    });

    if (data.status === "error") {
      throw data;
    }

    return data.results;
  }

  async getBrand(brandId) {
    try {
      const { data } = await axios.get(`/brands/${brandId}`);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }

  async addBrand(payload) {
    try {
      const { data } = await axios.post("/brands", payload);

      if (data.status === "error") {
        toast.error(data.results?.[0]?.msg);
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success("Added brand successfully");
      }

      return data;
    } catch (error) {
      throw Error(error);
    }
  }

  async editBrand(payload) {
    try {
      const { data } = await axios.put(`/brands/${payload.id}`, payload.data);

      if (data.status === "error") {
        const errorMessage = getErrorMessage(data);
        toast.error(errorMessage);
        return data;
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return { ...data, ...payload };
    } catch (error) {
      return error;
    }
  }

  async deleteBrand(id) {
    try {
      const { data } = await axios.delete(`/brands/${id}`);

      if (data.status === "error") {
        toast.error(data.message);
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return id;
    } catch (error) {
      throw Error(error);
    }
  }

  async archive(id, isArchive) {
    try {
      const { data } = await axios({
        url: `/brands/${id}`,
        method: isArchive ? "UNLINK" : "LINK",
      });

      if (data.status === "error") {
        toast.error(data.message);
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }
}

export default new BrandService();
