import { useCallback, memo, useState } from "react";
import { useFormikContext } from "formik";
import { debounce } from "lodash";

import RequestService from "@atd/features/request/requestApi";

import AssociateBrand from "./AssociateBrand/AssociateBrand";
import AttachReference from "./AttachReference";
import FileFormat from "./FileFormat";
import Inspirations from "./Inspirations";
import ProjectDetails from "./ProjectDetails";
import RequestName from "./RequestName";
import RequestType from "./RequestType";
import RoyalImages from "./RoyalImages/RoyalImages";
import VideoUrl from "./VideoUrl";

import AnyFormat from "../../../../assets/images/design-software-icons/any.png";
import PsdFormat from "../../../../assets/images/design-software-icons/psd-format.png";
import AiFormat from "../../../../assets/images/design-software-icons/ai-format.png";
import IdFormat from "../../../../assets/images/design-software-icons/id-format.png";
import PdfFormat from "../../../../assets/images/design-software-icons/pdf-format.png";
import JpegFormat from "../../../../assets/images/design-software-icons/jpeg-format.png";

const fileOptions = [
  {
    id: ".any",
    name: "Any",
    value: AnyFormat,
    category: "design",
  },
  {
    id: ".ps",
    name: "Photoshop",
    value: PsdFormat,
    category: "design",
  },
  {
    id: ".ai",
    name: "Illustrator",
    value: AiFormat,
    category: "design",
  },
  {
    id: ".indd",
    name: "Indesign",
    value: IdFormat,
    category: "design",
  },
  {
    id: ".pdf",
    name: "PDF",
    value: PdfFormat,
    category: "other",
  },
  {
    id: ".jpeg",
    name: "JPEG",
    value: JpegFormat,
    category: "other",
  },
];

function FormBody({
  requestId,
  brands,
  brandsLoading,
  activeBrand,
  attachments,
  isUploading,
  onClickAttachment,
  onRemoveAttachment,
  inputProps,
}) {
  const formik = useFormikContext();

  const [designTypeOptions, setDesignTypeOptions] = useState([]);
  const [activeRequestType, setActiveRequestType] = useState({});

  const { values, errors, touched, setFieldValue } = formik;

  const { file_type } = values || {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeField = useCallback(
    debounce(async (payload) => {
      const currentPayload = { ...payload };
      if (
        currentPayload.file_dimentions.length === 0 &&
        typeof currentPayload.file_dimentions === "string"
      ) {
        Object.assign(currentPayload, { file_dimentions: [] });
      }
      if (
        currentPayload.file_dimentions &&
        typeof currentPayload.file_dimentions === "string"
      ) {
        const dimensions = [
          {
            category_id: payload.dimentions[0].category_id,
            category_item_id: payload.dimentions[0].category_item_id,
            icon: payload.dimentions[0].icon,
            title_id: "custom",
            dimension_string: currentPayload.dimentions[0]?.dimension_string,
            height: 0,
            length: 0,
            units: "px",
          },
        ];
        Object.assign(currentPayload, { file_dimentions: dimensions });
      }
      await RequestService.updateRequest({
        data: {
          ...currentPayload,
          dimentions: undefined,
          category_item_id: undefined,
        },
        id: requestId,
      });
    }, 500),
    [requestId],
  );

  const onChangeInput = useCallback(
    async ({ target }) => {
      const { name, value } = target;
      setFieldValue(name, value);

      await onChangeField({
        ...values,
        [name]: value,
      });
    },
    [values, onChangeField, setFieldValue],
  );

  const onSetFieldValue = useCallback(
    async (field, value) => {
      if (!field) {
        const objValues = {};
        // eslint-disable-next-line no-restricted-syntax
        for (const key in value) {
          if (Object.hasOwnProperty.call(value, key)) {
            const fieldValue = value[key];
            Object.assign(objValues, { [key]: fieldValue });
            setFieldValue(key, fieldValue);
          }
        }
        await onChangeField({ ...values, ...objValues });
      } else {
        const newValue = { ...values, [field]: value };
        setFieldValue(field, value);
        await onChangeField(newValue);
      }
    },
    [values, onChangeField, setFieldValue],
  );

  return (
    <div className="Box-root">
      <RequestName
        {...formik}
        activeRequestType={activeRequestType}
        designTypeOptions={designTypeOptions}
        onChange={onChangeInput}
        onSetFieldValue={onSetFieldValue}
        setActiveRequestType={setActiveRequestType}
      />
      <RequestType
        {...formik}
        request={requestId}
        activeRequestType={activeRequestType}
        designTypeOptions={designTypeOptions}
        setDesignTypeOptions={setDesignTypeOptions}
        onSetFieldValue={onSetFieldValue}
        setActiveRequestType={setActiveRequestType}
      />
      <FileFormat
        value={file_type}
        errors={errors}
        touched={touched}
        fileOptions={fileOptions}
        onChange={onSetFieldValue}
      />
      {file_type?.length > 0 && (
        <AssociateBrand
          requestId={requestId}
          brands={brands}
          loading={brandsLoading}
          activeBrand={activeBrand}
          onChange={onChangeInput}
          onSetFieldValue={onSetFieldValue}
        />
      )}
      <ProjectDetails {...formik} onSetFieldValue={onSetFieldValue} />
      <AttachReference
        uploading={isUploading}
        open={onClickAttachment}
        files={attachments}
        inputProps={inputProps}
        onRemove={onRemoveAttachment}
        onChange={onChangeInput}
      />
      <RoyalImages {...formik} onSetFieldValue={onSetFieldValue} />
      <Inspirations onChange={onChangeInput} />
      <VideoUrl onChange={onChangeInput} />
    </div>
  );
}

export default memo(FormBody);
