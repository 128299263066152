import moment from "moment";
import styled from "styled-components";

import { Box, Tooltip } from "@atd/components";

import Timer from "../Timer";
import ActivityText from "./ActivityText";

import {
  AssignUserText,
  ActivityContent,
  StatusLabelMain,
  SplitPipelineLabel,
} from "./Activity.style";

const AcitivityDate = styled.div``;

function Status(props) {
  const {
    request,
    created,
    user_name,
    activity,
    old_status_name,
    new_status_name,
    activity_type,
    from,
    to,
  } = props;

  const { request_name } = request || {};

  const renderRequestName = (nameString) => {
    if (nameString?.length > 24) {
      return (
        <Tooltip content={nameString}>
          <strong>
            {nameString?.substr(0, 24)}
            ...
          </strong>
        </Tooltip>
      );
    }

    return <strong>{nameString}</strong>;
  };

  const isArchive = activity_type === "archive" ? "Archive" : "Unarchive";
  const isArchiveUnarchive = ["archive", "unarchive"].includes(activity_type)
    ? isArchive
    : renderRequestName(request_name);
  const fromText =
    activity_type === "status_change" ? old_status_name : isArchiveUnarchive;

  const isAssignUser = ["assign_user", "reassign_user"].includes(activity_type)
    ? to
    : ["archive", "unarchive"].includes(activity_type);
  const isStatusChange =
    activity_type === "status_change" ? new_status_name : isAssignUser;
  const isStatus = isStatusChange ?? renderRequestName(request_name);
  const toText = activity_type === "in_progress" ? "In Progress" : isStatus;

  if (activity_type === "timer_status") {
    return <Timer activity={activity} />;
  }

  return (
    <Box flex margin={{ bottom: 20 }}>
      <ActivityContent
        flex={{ alignItems: "center", justifyContent: "space-between" }}
        style={{ flex: 1 }}
      >
        <AssignUserText>
          {["archive", "unarchive"].includes(activity_type) && (
            <ActivityText label={user_name} />
          )}
          {activity_type === "assign_user" && (
            <ActivityText label={from} text="Assigned" />
          )}
          {activity_type === "reassign_user" && (
            <ActivityText label={user_name} text="has reassigned the" />
          )}
          {activity_type === "status_change" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="status changed from"
            />
          )}
          {activity_type === "in_progress" && (
            <ActivityText
              style={{ marginRight: 5 }}
              label={user_name}
              text="changed status of"
            />
          )}
          <StatusLabelMain>
            <SplitPipelineLabel>{fromText}</SplitPipelineLabel>
          </StatusLabelMain>{" "}
          →{" "}
          <StatusLabelMain>
            <SplitPipelineLabel>{toText}</SplitPipelineLabel>
          </StatusLabelMain>{" "}
        </AssignUserText>
        <AcitivityDate>
          {moment(created).format("MMM DD, YYYY hh:mm A")}
        </AcitivityDate>
      </ActivityContent>
    </Box>
  );
}

export default Status;
