import styled from "styled-components";

import { Box } from "@atd/components";

export const AssignUserText = styled(Box)`
  padding-top: 4px;
`;

export const ActivityContent = styled(Box)`
  background: var(--light-color);
  border: 1px solid #e3e8ee;
  box-sizing: border-box;
  border-radius: 2px;
  color: #666;
  padding: 10px 20px;
`;

export const StatusLabelMain = styled.span`
  display: inline-block;
  padding: 2px 7px;
  flex: 0 0 auto;
  font-size: 12px;
  line-height: 1.45454545;
  color: rgba(0, 0, 0, 0.75);
  border-radius: 3px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 275px;
  box-sizing: border-box;
  vertical-align: middle;
  white-space: nowrap;
  margin-top: -4px;
`;

export const SplitPipelineLabel = styled.div`
  display: inline-block;
  padding: 3px 8px;
  background-color: #e3e8ee;
  border-radius: 5px;
  color: #4f566b;
`;
