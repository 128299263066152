/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from "react-redux";
import HeaderContent from "./components/AuthHeader";
import HeaderNav from "./components/HeaderNav";
import PlanModal from "./components/PlanModal";
import { useSearchParams } from "react-router-dom";
import {
  getModalContent,
  // setActivePlan as setCurrentPlanDetails,
  setCurrentPlan,
} from "@atd/features";
import React, { useEffect, useState } from "react";

const TemplateMain = ({ children, page, navBack }) => {
  const dispatch = useDispatch();

  const [selectedPlan, setSelectedPlan] = useState("monthly");
  const { modalContent, currentPlan } = useSelector((store) => store.auth);
  const [searchParams] = useSearchParams();

  const queryParams = {
    deals: searchParams.get("deals"),
    plan_id: searchParams.get("plan_id"),
    offers: searchParams.get("offers"),
  };

  useEffect(() => {
    dispatch(getModalContent(queryParams));
  }, []);

  const setPlans = (plan) => {
    console.log("pln thes:", plan);
    setSelectedPlan(plan);
  };
  console.log("modalContent", modalContent);
  return (
    <div className="fs-custom">
      <PlanModal
        modalContent={modalContent}
        selectedPlan={selectedPlan}
        queryParams={queryParams}
        setSelectedPlan={setSelectedPlan}
      />
      <div className="co-page-wrapper">
        <HeaderContent />
        <div className="co-content-wrapper wf-section">
          <HeaderNav page={page} navBack={navBack} />
          {/* content */}
          {children}
        </div>
      </div>
    </div>
  );
};

export default TemplateMain;
