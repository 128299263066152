import { Box, Button } from "@atd/components";

import { CardText, Table, TitleStyle } from "./Payment.style";

function PaymentMethod({
  cardData,
  showCardUpdate,
  cardUpdateUrl,
  type,
  onShowAddCardModal,
  isSubscriptionActive,
}) {
  return (
    <>
      <TitleStyle>Payment Method</TitleStyle>
      <Box className="cont1" style={{ display: "block" }}>
        {/* Payments for your chosen subscriptions will be charged to the below
        {type === "bank_account" ? " bank account" : " card"}: */}
        The payment method specified below will be charged for your
        subscriptions
      </Box>
      {cardData?.length === 0 && showCardUpdate && (
        <Box margin={{ top: 8 }}>
          <Button
            variant="primary"
            label="Update"
            onClick={() => window.open(cardUpdateUrl, "_blank")}
          />
        </Box>
      )}
      <Table className="table wt-100 mgt1">
        <tbody>
          {cardData &&
            cardData.length > 0 &&
            cardData.map((cardInfo, index) => (
              <tr key={`pm-${cardInfo?.last4}-${index}`}>
                <td align={type === "bank_account" ? "center" : ""}>
                  <img
                    src={
                      type === "bank_account"
                        ? "https://cdn-icons-png.flaticon.com/512/3820/3820258.png"
                        : "https://spiceblue.co/atd_ui/atdapp/client/assets/images/card.png"
                    }
                    height={24}
                    alt="Visa"
                  />
                </td>
                <td className="bl-cont">
                  <CardText>
                    {type === "card" ? "Visa" : cardInfo?.bank_name}
                  </CardText>{" "}
                  {cardInfo.masked_number}
                </td>
                <td className="bl-cont">
                  {type === "card" && (
                    <>
                      <CardText>Expires:</CardText> {cardInfo.expiry_month}/
                      {cardInfo.expiry_year}
                    </>
                  )}
                </td>
                <td>
                  {showCardUpdate && isSubscriptionActive ? (
                    <Button variant="primary" onClick={onShowAddCardModal}>
                      Update
                    </Button>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </>
  );
}

export default PaymentMethod;
