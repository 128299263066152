/* eslint-disable jsx-a11y/label-has-associated-control */
import { FormFieldInputError } from "@atd/components";

function FileFormat({ fileOptions, value, errors, touched, onChange }) {
  return (
    <>
      <div className="title2 mgt4">
        File Format
        <span style={{ color: "red" }}>*</span>
      </div>
      <div className="cont4">
        Which format would you prefer that the design files be delivered in?
      </div>
      <div className="FormField mgt1" id="fformat">
        <div className="checkbox-group">
          {fileOptions.map((op) => (
            <div key={op.id} className="checkbox">
              <label htmlFor={op.id} className="checkbox-wrapper">
                <input
                  id={op.id}
                  name="file_type"
                  type="checkbox"
                  value={op.id}
                  className="checkbox-input"
                  checked={value.includes(op.id)}
                  onChange={(e) => {
                    const { checked } = e.target;
                    if (checked) {
                      onChange("file_type", [...value, op.id]);
                    } else {
                      onChange(
                        "file_type",
                        value.filter((v) => v !== op.id),
                      );
                    }
                  }}
                />
                <span
                  className="checkbox-tile"
                  style={{
                    border:
                      errors.file_type &&
                      touched.file_type &&
                      "1px solid #f44336",
                  }}
                >
                  <span className="checkbox-icon">
                    <img src={op.value} alt={op.name} />
                  </span>
                  <span className="checkbox-label cont4 bold">{op.name}</span>
                </span>
              </label>
            </div>
          ))}
          <FormFieldInputError name="file_type" />
        </div>
      </div>
    </>
  );
}

export default FileFormat;
