import * as React from "react";

function SvgClient(props) {
  return (
    <svg
      className="Client_svg__SVGInline-svg Client_svg__SVGInline--cleaned-svg Client_svg__SVG-svg Client_svg__db-SideNav-icon-svg Client_svg__db-SideNav-icon--connect-svg Client_svg__wh-16"
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none">
        <path
          d="M8.002 16.004A8.002 8.002 0 118.002 0c.455 0 .9.047 1.334.12V5h1.666v1.668h4.882c.073.435.12.88.12 1.334a8.002 8.002 0 01-8.002 8.002zm1.992-6.01H6.348a.34.34 0 01-.34-.34v-3.64h-2.44a.338.338 0 00-.24.1l-2.23 2.23a6.905 6.905 0 006.57 6.563l2.227-2.228c.063-.063.1-.15.1-.24V9.992z"
          fill="#fff"
          className="db-SideNav-iconFill--secondary"
        />
        <path
          d="M15.886 6.686L12.68 9.894a.338.338 0 01-.24.1H9.994V6.352a.338.338 0 00-.338-.338H6.009V3.559a.34.34 0 01.1-.24l3.2-3.203a7.993 7.993 0 016.577 6.57z"
          fill="#4F566B"
          className="db-SideNav-iconFill--primary"
        />
      </g>
    </svg>
  );
}

export default SvgClient;
