import { useState } from "react";
import { Tab, TabPanel } from "react-tabs";
import Sticky from "react-sticky-el";

import { Box } from "@atd/components";
import { isAdmin } from "@atd/utils";
import { useGetUpgradeQuery } from "@atd/features/user";

import RequestDetail from "./RequestDetail";
import BrandDetail from "./BrandDetail";
import Timer from "./Timer/Timer";
import Status from "./Status";
import TotalTime from "./TotalTime";
import MarketingVideo from "./MarketingVideo";

import {
  RequestInfoStyle,
  TabListStyle,
  RequestInfoTabs,
} from "./Request.style";

function RequestInfo({
  role,
  users,
  online,
  user_name,
  timer_status,
  company_id,
  company_data,
  brand_data,
  total_duration,
  in_process_seconds,
  client_time_zone,
  ...props
}) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const { data } = useGetUpgradeQuery({});

  const { code } = data || {};

  const ContainerComponent = activeTabIndex === 0 ? Sticky : "div";

  return (
    <RequestInfoStyle
      className="flx3"
      flex={{ direction: "column" }}
      style={{ position: "relative" }}
    >
      <ContainerComponent scrollElement=".atd-World-mainPane">
        <Box padding={{ right: 0, left: 20, vertical: 20 }}>
          <Box
            flex={{ direction: "column", justifyContent: "flexStart" }}
            className="ht-100"
          >
            {!isAdmin() && code === 200 && (
              <MarketingVideo plan={company_data?.plan_name} />
            )}
            {isAdmin() && (
              <>
                <Timer
                  ticketStatus={props.status}
                  status={props.status === 7 ? "complete" : timer_status}
                  value={in_process_seconds}
                />
                <TotalTime time={total_duration} />
                <Status
                  role={role}
                  isOnline={online}
                  name={user_name}
                  companyId={company_id}
                  clientTimezone={client_time_zone}
                  companyName={company_data?.company_name}
                />
                <RequestInfoTabs
                  selectedTabClassName="active"
                  selectedIndex={activeTabIndex}
                  onSelect={(index) => setActiveTabIndex(index)}
                >
                  <TabListStyle className="tabs Padding-all--0 Margin-bottom--0 Request__info--tabs">
                    <Tab className="tab-item capitalize">Details</Tab>
                    <Tab className="tab-item capitalize">Brands</Tab>
                  </TabListStyle>
                  <TabPanel>
                    <RequestDetail users={users} {...props} />
                  </TabPanel>
                  <TabPanel>
                    <BrandDetail brand={brand_data} />
                  </TabPanel>
                </RequestInfoTabs>
              </>
            )}
          </Box>
        </Box>
      </ContainerComponent>
    </RequestInfoStyle>
  );
}

export default RequestInfo;
