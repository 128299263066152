import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CreateBrand from "../../../../../assets/images/create-new-brand.png";

function AssociateBrandCreate({ requestId, onSetFieldValue }) {
  const navigate = useNavigate();

  const { brand } = useSelector((state) => state.brand);

  useEffect(() => {
    if (brand) {
      onSetFieldValue("brand_id", brand.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brand]);

  return (
    <div
      aria-hidden="true"
      className="checkbox"
      onClick={() =>
        navigate("/brands/new", { state: { from: `/requests/${requestId}` } })
      }
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor="create-brand" className="checkbox-wrapper">
        <span className="create-tile">
          <span className="checkbox-label">
            <img src={CreateBrand} alt="ATD" className="va1" />{" "}
            <span className="va2">Create New</span>
          </span>
        </span>
      </label>
    </div>
  );
}

export default AssociateBrandCreate;
