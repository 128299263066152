import styled from "styled-components";

import ATD_LOGO from "../../assets/atd-logo.svg";
import BG_1 from "../../assets/images/bg-1.png";
import BG_2 from "../../assets/images/bg-2.png";
import BG_3 from "../../assets/images/bg-3.png";

const AuthWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: rgb(239 246 255); */
  background-color: #000;
`;

const AuthContainer = styled.div`
  width: 800px;
  background-color: #f8f8f8;
  display: flex;
  align-items: stretch;
  box-shadow: 0 0 18px 4px rgba(0, 0, 0, 0.1);
  position: relative;

  & a {
    color: #ea552b;
    font-weight: 400;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
  }

  &::before {
    top: -90px;
    right: -85px;
    background: url(${BG_1}) no-repeat;
    width: 270px;
    height: 280px;
  }

  &::after {
    bottom: -155px;
    left: -122px;
    background: url(${BG_2}) no-repeat;
    width: 360px;
    height: 410px;
  }
`;

const AuthFormContainer = styled.div`
  width: 50%;
  padding: 35px;
  text-align: center;
  background-color: #f8f8f8;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    top: -70px;
    left: 50px;
    width: 50px;
    height: 37px;
    background: url(${BG_3}) no-repeat;
    background-size: contain;
  }

  & img {
    display: block;
    width: 68px;
    margin-bottom: 42px;
  }
  & h3 {
    font-size: 22px;
    font-weight: 500;
  }
  & form {
    padding: 1.5rem 0;
  }
  & form input:not([type="checkbox"]) {
    width: 100%;
    padding: 0.5rem 0.75rem;
    outline: none;
    border: 1px solid rgb(209, 213, 219);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
  }
  & form button {
    width: 100%;
    background-color: #2c2c2c;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0.65rem 0.75rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
  }
`;

const AuthContent = styled.div`
  width: 50%;
  padding: 35px;
  background-color: #dce5ee;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;

  & h2 {
    color: #3c4257;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.8rem;
  }
  & p {
    color: #3c4257;
  }

  & a {
    color: #f7f7f7;
  }

  & form {
    /* padding: 1.5rem 0; */
    color: white;
  }

  & form input:not([type="checkbox"]),
  select {
    width: 100%;
    padding: 0.5rem 0.75rem;
    outline: none;
    border: 0;
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    color: #333;
  }

  & form select {
    -moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    padding-right: 40px;
    padding-top: 5px;
    height: 2.2rem;
  }

  & form button {
    width: 100%;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 0.25rem;
    padding: 0.65rem 0.75rem;
    cursor: pointer;
    margin-bottom: 1.5rem;
  }
  & form button:hover {
    background-color: black;
  }
`;

function Auth({
  title,
  renderLeftContent,
  renderRightContent,
  description,
  children,
}) {
  // useEffect(() => {
  //   if (checkCredentialCookies()) {
  //     navigate("/requests");
  //   }
  // }, [navigate]);
  console.log("Auth");

  return (
    <div>
      <AuthWrapper>
        <AuthContainer>
          <AuthFormContainer>
            <a href="https://alltimedesign.com/">
              <img
                style={{ cursor: "pointer" }}
                src={ATD_LOGO}
                alt="All Time Design Logo"
              />
            </a>
            {renderLeftContent ? (
              renderLeftContent()
            ) : (
              <>
                <h3>{title}</h3>
                {description}
                {children}
              </>
            )}
          </AuthFormContainer>
          <AuthContent>
            {renderRightContent ? (
              renderRightContent()
            ) : (
              <>
                <h2>Here To Help</h2>
                <p>
                  No matter where you are in your journey, we&rsquo;re here to
                  help. Do you have any queries? Reach out our Customer Success
                  team
                </p>
              </>
            )}
          </AuthContent>
        </AuthContainer>
      </AuthWrapper>
    </div>
  );
}

export default Auth;
