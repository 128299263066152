import { toast } from "react-toastify";
import moment from "moment";

import { axios, getCurrentTimeZone } from "@atd/utils";

class ClientService {
  async getAllClients(params, cancelToken) {
    try {
      let data = {};
      if (params?.status === "trial_users") {
        data = await axios.get("/trial", {
          params: { ...params, time_zone: getCurrentTimeZone() },
          cancelToken,
        });
      } else {
        data = await axios.get("/clients", {
          params: { ...params, time_zone: getCurrentTimeZone() },
          cancelToken,
        });
      }

      if (data.status === "error") {
        throw data.data;
      }

      return data.data;
    } catch (err) {
      if (err?.code !== "ERR_CANCELED") {
        toast.error(err.message);
      }
      throw err;
    }
  }

  async getClient(Id, { id, index, tab, ...rest }) {
    try {
      const { data } = await axios.get(`/clients/${Id}`, {
        rest,
      });

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }

  async addClient(payload) {
    try {
      const { data } = await axios.post("/clients", payload);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return data.results;
    } catch (error) {
      throw Error(error);
    }
  }

  async updateClient(id, payload) {
    try {
      const { data } = await axios.put(`/clients/${id}`, payload);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      if (data.status === "success") {
        toast.success(data.message);
      }

      return payload;
    } catch (error) {
      toast.error(error);
      throw Error(error);
    }
  }

  async deleteClient(id) {
    try {
      const { data } = await axios.delete(`/clients/${id}`);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return id;
    } catch (error) {
      throw Error(error);
    }
  }

  async exportClients() {
    try {
      const { data } = await axios({
        method: "GET",
        url: "/clients_export",
        responseType: "blob",
        params: {
          v: Math.floor(Date.now() * Math.random()),
        },
      });

      if (data.status === "error") {
        throw new Error(data.message);
      }

      // Let's create a link in the document that we'll
      // programmatically 'click'.
      const link = document.createElement("a");

      // Tell the browser to associate the response data to
      // the URL of the link we created above.
      link.href = window.URL.createObjectURL(new Blob([data]));

      // Tell the browser to download, not render, the file.
      link.setAttribute(
        "download",
        `clients-${moment().format("MM-DD-YYYY-hh-mm-ss")}.xlsx`,
      );

      // Place the link in the DOM.
      document.body.appendChild(link);

      // Make the magic happen!
      link.click();
    } catch (error) {
      throw Error(error);
    }
  }

  async getClientsCount(params) {
    try {
      const { data } = await axios.get("/client_count", { params });
      if (data.status === "error") {
        toast.error(data.message);
      }
      return data.results;
    } catch (err) {
      toast.error(err.message);
      return err;
    }
  }
  async getAllMeetings(id) {
    try {
      const { data } = await axios.get(`/clients_meeting/${id}`);
      if (data.status === "error") {
        toast.error(data.message);
      }
      return data.results;
    } catch (err) {
      toast.error(err.message);
      return err;
    }
  }
  async getSingleClientMeeting({ id, meetingId }) {
    try {
      const { data } = await axios.get(`/clients_meeting/${id}/${meetingId}`);
      if (data.status === "error") {
        toast.error(data.message);
      }
      return data.results;
    } catch (err) {
      toast.error(err.message);
      return err;
    }
  }
  async getClientMeetingFilters(id) {
    try {
      const { data } = await axios.get(`/clients_meeting_filters/${id}`);
      if (data.status === "error") {
        toast.error(data.message);
      }
      return data.results;
    } catch (err) {
      toast.error(err.message);
      return err;
    }
  }
  async postClientMeeting(id, payload) {
    try {
      const res = await axios.post(`/clients_meeting/${id}`, payload);
      if (res.data.status === "error") {
        if (res.data.message === "Validation error") {
          toast.error("Please enter the required fields");
        } else {
          toast.error(res.data.message);
        }
      }
      return res;
    } catch (err) {
      if (err.message === "Validation error") {
        toast.error("Please enter the required fields");
      } else {
        toast.error(err.message);
      }
      return err;
    }
  }
  async putClientMeeting({ id, meetingId, payload }) {
    try {
      const res = await axios.put(
        `/clients_meeting/${id}/${meetingId}`,
        payload,
      );
      if (res.data.status === "error") {
        if (res.data.message === "Validation error") {
          toast.error("Please enter the required fields");
        } else {
          toast.error(res.data.message);
        }
      }
      return res;
    } catch (err) {
      if (err.message === "Validation error") {
        toast.error("Please enter the required fields");
      } else {
        toast.error(err.message);
      }
      return err;
    }
  }

  async deleteClientMeeting(id, value) {
    try {
      const { data } = await axios.delete(`/clients_meeting/${id}/${value}`);

      if (data.status === "error") {
        throw new Error(data.message);
      }

      return id;
    } catch (error) {
      throw Error(error);
    }
    // return value;
  }

  async fetchCustomer(
    {
      page,
      created_date,
      plans,
      designer,
      location,
      email,
      status,
      tab,
      time_zone = getCurrentTimeZone(),
    },
    cancelToken,
  ) {
    try {
      const { data } = await axios.get("/customer_feedback", {
        params: {
          page,
          tab,
          created_date,
          designer,
          plans,
          location,
          email,
          status,
          time_zone,
        },
        cancelToken,
      });

      if (data.status === "error") {
        throw data;
      }

      return data;
    } catch (err) {
      toast.error(err.message);
      throw err;
    }
  }
}

export default new ClientService();
