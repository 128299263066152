import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { removeCredentialCookies } from "@atd/utils";

import AuthApi from "./authApi";
import axiosClient from "../../app/axiosClient";
// import axios from "axios";

export const loginUserAsync = createAsyncThunk(
  "auth/loginUserAsync",
  (payload) => AuthApi.login(payload.userCredentials),
);

export const getModalContent = createAsyncThunk(
  "/auth/getModalContent",
  async (queryParams) => {
    try {
      const price = {
        s_1: "299",
        p_1: "599",
        pr_1: "1019",
        ul_1: "2999",
      };
      const planId = {
        s_1: "s_1",
        p_1: "p_1",
        pr_1: "pr_1",
        ul_1: "ul_1",
      };
      const couponCode = {
        hbvjikg: "hbvjikg",
      };

      const { data } = await axiosClient.get("/plans");
      if (
        planId[queryParams.plan_id] === queryParams.plan_id &&
        couponCode[queryParams.deals] === queryParams.deals
      ) {
        const planList = data.results.plans.reduce((acc, curr) => {
          if (curr.id === "monthly") {
            const monthlyList = curr.data.map((list) => {
              return {
                ...list,
                total_offer_price: price[list.id],
                offer_price: price[list.id],
              };
            });
            curr.data = monthlyList;
            curr.offer = 0;
          }
          acc.push(curr);
          return acc;
        }, []);
        console.log("Modify", planList);
        return planList;
      } else {
        return queryParams?.offers === "off_50"
          ? data.results.plans_offers
          : data.results.plans;
      }
    } catch (error) {
      console.log(error.message);
      return [];
    }
  },
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (userId, { dispatch }) => {
    const response = await AuthApi.logout();
    if (response) {
      await dispatch(removeUser());
      removeCredentialCookies();
      window.localStorage.removeItem(`${userId}-requests-filters`);
      window.location.href = "/login";
    }
  },
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",

  async (payload) => AuthApi.forgot(payload),
);

export const resetPassword = createAsyncThunk("auth/resetPassword", (payload) =>
  AuthApi.reset(payload),
);

export const accept = createAsyncThunk("auth/accept", (payload) =>
  AuthApi.accept(payload),
);

export const queryParam = (data) => {
  let offers_status = data && data.offers === "off_50";
  return !!offers_status;
};

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    hasErrors: false,
    errorMsg: "",
    user: null,
    error: null,
    isOpenModal: false,
    modalContent: [],
    //plan details based on plan id
    planDetails: {},
    // for actual plan details
    currentPlan: queryParam() ? "s_11" : "s_1",
    // stripe page loading button
    isFormSubmitting: false,
    //payment page plan cards
    planCards: [],
    // dpu count
    quantity: 1,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    removeUser: (state) => {
      state.user = null;
    },
    openModal: (state) => {
      state.isOpenModal = true;
    },
    closeModal: (state) => {
      state.isOpenModal = false;
    },
    setCurrentPlan: (state, action) => {
      state.currentPlan = action.payload;
    },
    setFormSubmitting: (state, action) => {
      state.isFormSubmitting = action.payload;
    },
    //payment page cards
    setPaymentCards: (state, action) => {
      state.planCards = Object.keys(state.planDetails).reduce((acc, item) => {
        if (state.planDetails[item].name === action.payload) {
          return [...acc, state.planDetails[item]];
        }
        return acc;
      }, []);
    },
    setActivePlanCards: (state, action) => {
      state.planCards = state.planCards.filter(
        (item) => item.period >= action.payload,
      );
    },

    //dpu count
    setQuantity: (state, action) => {
      if (action.payload === "INC") {
        state.quantity = state.quantity + 1;
        return;
      }
      if (action.payload === "DEC") {
        if (state.quantity <= 1) {
          state.quantity = 1;
          return;
        }
        state.quantity = state.quantity - 1;
      }
    },
  },
  extraReducers: {
    [loginUserAsync.pending]: (state) => {
      state.loading = true;
      state.hasErrors = false;
      state.errorMsg = "";
      state.user = null;
    },
    [loginUserAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.hasErrors = false;
      state.errorMsg = "";
      state.user = action.payload.results;
    },
    [loginUserAsync.rejected]: (state, action) => {
      state.loading = false;
      state.hasErrors = true;
      state.errorMsg = action.error.message;
      state.user = null;
    },
    [getModalContent.fulfilled]: (state, action) => {
      state.modalContent = action.payload.filter(
        (plan) => plan.id !== "half_yearly",
      );
      state.planDetails = state.modalContent.reduce((acc, head) => {
        return {
          ...acc,
          ...head.data.reduce((acc, item) => {
            return {
              ...acc,
              [item.id]: { ...item, period: head.period, planType: head.name },
            };
          }, {}),
        };
      }, {});
    },
    [getModalContent.rejected]: (state, action) => {
      state.modalContent = [];
    },
  },
});

export const {
  setUser,
  removeUser,
  openModal,
  closeModal,
  setActivePlanCards,
  setCurrentPlan,
  setFormSubmitting,
  setPaymentCards,
  setQuantity,
} = authSlice.actions;

export default authSlice.reducer;
