import authReducer from "../features/auth/authSlice";
import requestReducer from "../features/request/requestSlice";
import requestThreadReducer from "../features/request/requestThreadSlice";
import brandReducer from "../features/brand/brandSlice";
import teamReducer from "../features/team/teamSlice";
import rolesReducer from "../features/role/roleSlice";
import clientReducer from "../features/client/clientSlice";
import userReducer from "../features/user/userSlice";
import noteReducer from "../features/note/noteSlice";
import templateReducer from "../features/template/templateSlice";
import activityReducer from "../features/activity/activitySlice";

import { accountApi } from "../features/user/accountApi";
import { activityApi } from "../features/activity/activityApi";
import { teamReportApi } from "../features/team/teamApi";

const rootReducer = {
  auth: authReducer,
  requests: requestReducer,
  requestThread: requestThreadReducer,
  brand: brandReducer,
  team: teamReducer,
  roles: rolesReducer,
  client: clientReducer,
  user: userReducer,
  note: noteReducer,
  template: templateReducer,
  activity: activityReducer,
  [accountApi.reducerPath]: accountApi.reducer,
  [activityApi.reducerPath]: activityApi.reducer,
  [teamReportApi.reducerPath]: teamReportApi.reducer,
};

export default rootReducer;
