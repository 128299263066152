import * as React from "react";

function SvgVideo(props) {
  return (
    <svg
      id="video_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 32 25.6"
      style={{
        enableBackground: "new 0 0 32 25.6",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>{".video_svg__st1{fill:#f73c2b}"}</style>
      <g id="video_svg___x35_Rj6As_00000091714035731202953860000016577375336810866059_">
        <path
          d="M29.5 5.6c0-1-.7-1.8-1.8-1.8H4.2c-1 0-1.7.7-1.7 1.7v14.6c.2.8.8 1.3 1.7 1.3h23.6c1 0 1.8-.7 1.8-1.8-.1-4.6-.1-9.3-.1-14zm-3.8.4c0-.3.2-.5.5-.5h1.6c.3 0 .5.2.5.5v.8c0 .3-.2.5-.5.5h-1.6c-.3 0-.4-.2-.4-.5-.1-.3-.1-.6-.1-.8zm0 4.1c0-.3.2-.4.4-.4h1.6c.3 0 .4.2.4.4v.8c0 .2-.2.4-.4.4H26c-.3 0-.4-.2-.4-.4.1-.2.1-.5.1-.8zM3.8 6c0-.3.2-.5.5-.5h1.6c.3 0 .5.2.5.5v.7c0 .3-.2.5-.5.5H4.3c-.4 0-.5-.2-.5-.5V6zm2.5 13.4c0 .3-.2.5-.5.5H4.2c-.3 0-.5-.2-.5-.5v-.8c0-.3.2-.5.5-.5h1.6c.3 0 .5.2.5.5v.8zm0-4.2c0 .3-.2.4-.4.4H4.3c-.2 0-.4-.2-.4-.4v-.9c0-.2.2-.4.4-.4H6c.2 0 .4.2.4.4-.1.3-.1.6-.1.9zm0-4.2c0 .2-.2.4-.4.4H4.2c-.2 0-.4-.2-.4-.4v-.8c0-.3.2-.4.4-.4h1.6c.3 0 .4.2.4.4.1.2.1.5.1.8zm17.2 8.8h-15c-.4 0-.7-.1-.9-.5-.1-.1-.1-.3-.1-.4V6.4c0-.5.4-.9.9-.9h15c.6 0 1 .3 1 1v12.4c0 .6-.3.9-.9.9zm4.7-.4c0 .3-.2.4-.4.5h-1.6c-.3 0-.4-.2-.4-.5v-.8c0-.3.2-.4.4-.4h1.6c.3 0 .5.2.5.5-.1.2-.1.4-.1.7zm0-4.2c0 .2-.2.4-.4.4h-1.6c-.3 0-.4-.2-.4-.4v-.8c0-.2.2-.4.4-.4h1.7c.2 0 .4.2.4.4-.1.2-.1.5-.1.8z"
          style={{
            fill: "#474e5e",
          }}
        />
        <path
          className="video_svg__st1"
          d="M20.2 12.4v.3c-.1.1-.3.1-.5.2-1 .2-2 .5-2.9.7l-2.7.6c-.3.1-.5 0-.7-.1-.1 0-.1-.1-.2-.2V11c.1-.2.3-.2.5-.3h.6c1.4.3 2.8.7 4.2 1 .5.1 1 .2 1.4.4.1.1.2.1.3.3z"
        />
        <path
          d="M13.1 13.9c.2.3.5.3.8.2 1-.2 1.9-.5 2.9-.7.5-.1 1-.3 1.6-.4.4-.1.9-.2 1.3-.3.1 0 .3-.1.4-.2h.1c0 .2-.2.3-.4.4-1 .5-2.1 1-3.1 1.5-.9.4-1.7.8-2.6 1.2-.4.2-1-.1-1-.6v-1.1z"
          style={{
            fill: "#d53123",
          }}
        />
        <path
          className="video_svg__st1"
          d="M13.1 10.3v-.6c0-.5.5-.8.9-.6.4.2.7.3 1 .5 1.5.7 3 1.5 4.6 2.2.2.1.4.2.5.4v.1c-.5-.3-1-.4-1.5-.6-.9-.3-1.8-.7-2.7-1l-1.8-.6c-.4-.2-.7-.2-1 .2.1 0 0 0 0 0z"
        />
        <path
          d="M13.1 10.3c.2-.4.7-.4 1-.3.6.2 1.1.4 1.7.6.6.2 1.2.4 1.7.6.7.2 1.3.5 2 .7.2.1.4.1.5.3 0 .1.1.1.1.2-.1-.1-.2-.2-.4-.2-1.1-.3-2.2-.5-3.3-.8l-2.4-.6c-.3-.1-.5 0-.8 0-.1 0-.1.1-.2.2.1-.3.1-.5.1-.7z"
          style={{
            fill: "#fd6e5c",
          }}
        />
      </g>
    </svg>
  );
}

export default SvgVideo;
