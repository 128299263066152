import * as React from "react";

function SvgXls(props) {
  return (
    <svg
      id="xls_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>{".xls_svg__st3{fill:#397048}"}</style>
      <g id="xls_svg__lTS6h8_00000058572635018012544980000001741112983175427718_">
        <path
          d="M18.8 117V43.1c0-.5.1-.7.6-.8L56 35.4c9.2-1.7 18.4-3.5 27.6-5.2 11.3-2.1 22.5-4.2 33.8-6.4 5.2-1 10.4-2 15.6-2.9.6-.1.7-.1.7.5v17.9c0 .8 0 .8.7.8h76.9c3 0 5.6 1 7.6 3.3 1.4 1.6 2.2 3.5 2.3 5.7V184.2c0 4.1-1.9 7.1-5.6 8.9-1.3.6-2.6.8-4 .8h-77.3c-.8 0-.8 0-.8.7v18c0 .4 0 .6-.6.5-7.3-1.4-14.5-2.7-21.8-4.1-9.7-1.8-19.3-3.6-29-5.5-9.6-1.8-19.3-3.6-28.9-5.5-8.6-1.6-17.3-3.2-25.9-4.9l-8.1-1.5c-.4-.1-.5-.2-.5-.6v-8.4c.1-21.8.1-43.7.1-65.6z"
          style={{
            fill: "#386e46",
          }}
        />
        <path
          d="M213.1 117v68.3c0 .6-.1.7-.7.7h-78.2c-.5 0-.7-.1-.7-.7v-11.1c0-.5.1-.6.6-.6h26.6c.5 0 .6-.1.6-.6v-16.3c0-.5-.1-.6-.6-.6h-26.6c-.5 0-.7-.1-.6-.6v-4.9c0-.5.1-.5.6-.5h26.7c.5 0 .7-.1.7-.7v-16.2c0-.5-.1-.6-.6-.6h-26.7c-.5 0-.6-.1-.6-.6v-5.4c0-.4.1-.5.6-.5h26.6c.6 0 .7-.1.7-.7v-16.3c0-.5-.1-.6-.6-.6h-26.6c-.5 0-.7-.1-.7-.7v-5.6c0-.5.2-.6.6-.6h26.6c.6 0 .7-.1.7-.7V84.7c0-.5-.2-.6-.7-.6h-26.6c-.5 0-.7-.1-.7-.7v-5.2c0-.5.1-.6.6-.6h26.5c.6 0 .7-.1.7-.7V60.7c0-.5-.2-.6-.7-.6h-26.5c-.5 0-.6-.1-.6-.6V48.4c0-.6.2-.6.7-.6H212.4c.5 0 .6.1.6.6.1 23 .1 45.8.1 68.6z"
          style={{
            fill: "#fdfdfd",
          }}
        />
        <path
          d="M97.8 81.7c-.2.5-.5 1-.7 1.4-5.6 10.6-11.1 21.2-16.7 31.8-.2.4-.2.7 0 1 5.9 11.1 11.8 22.2 17.7 33.4.1.1.1.3.2.5-.7 0-1.3-.1-1.9-.1-3.5-.2-7-.5-10.5-.7-1-.1-1.9-.1-2.9-.2-.4 0-.6-.2-.8-.6l-8.7-19.5c-.8-1.8-1.5-3.7-1.8-5.7 0-.1-.1-.2-.1-.3l-.1.1c-.7 3.4-2.2 6.4-3.6 9.5l-6.6 14.4c-.2.5-.5.6-.9.5-2.5-.2-5.1-.4-7.6-.5-1.8-.1-3.5-.3-5.4-.4.3-.5.5-1 .8-1.6l15.3-28.5c.2-.4.2-.6 0-1L49.1 85.8c0-.1-.1-.1-.1-.2-.4-.6-.4-.6.2-.6 3-.2 5.9-.4 8.9-.6 1.4-.1 2.8-.2 4.1-.3.3 0 .4 0 .6.3 2.6 6.4 5.2 12.7 7.8 19.1.5 1.3.9 2.7 1.2 4.1 0 .2.1.4.1.6.2 0 .2-.1.2-.2 1-4.2 2.9-8.1 4.6-12 1.9-4.2 3.8-8.5 5.8-12.7.2-.4.4-.6.9-.6 4.6-.3 9.1-.6 13.7-1 .3-.1.4-.1.7 0z"
          style={{
            fill: "#fefefe",
          }}
        />
        <path
          className="xls_svg__st3"
          d="M184.5 101.8h-15.3c-.5 0-.6-.1-.6-.6V84.9c0-.5.1-.6.6-.6h30.7c.5 0 .6.1.6.6v16.4c0 .5-.1.6-.6.6-5.2-.1-10.3-.1-15.4-.1zM184.4 60.3h15.4c.5 0 .6.1.6.6v16.4c0 .4-.1.6-.5.6h-30.8c-.5 0-.5-.1-.5-.6V60.9c0-.5.2-.6.6-.6h15.2zM184.4 173.7h-15.3c-.4 0-.6-.1-.6-.5v-16.4c0-.4.1-.5.6-.5h30.8c.4 0 .5.1.5.5v16.5c0 .4-.1.5-.5.5-5.2-.1-10.3-.1-15.5-.1zM184.5 108.7h15.3c.5 0 .6.1.6.6v16.4c0 .5-.1.6-.6.6h-30.7c-.5 0-.6-.1-.6-.6v-16.3c0-.5.1-.6.6-.6 5.1-.1 10.3-.1 15.4-.1zM184.5 132.7h15.3c.5 0 .6.1.6.6v16.3c0 .5-.1.6-.6.6h-30.7c-.5 0-.6-.1-.6-.6v-16.3c0-.5.1-.6.6-.6h15.4z"
        />
      </g>
    </svg>
  );
}

export default SvgXls;
