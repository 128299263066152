import * as React from "react";

function SvgPpt(props) {
  return (
    <svg
      id="ppt_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>{".ppt_svg__st15{fill:#c3573c}"}</style>
      <g id="ppt_svg__t5J3By_00000171678528962990287010000003970659393633312389_">
        <path
          d="M134.9 225.8h-.7c-4.6-.8-9.3-1-13.9-1.3-15.3-1.1-30.2-4.1-45.3-6.4-20.3-3-40.7-6.1-61-9.4-4.5-.7-5.1-1.3-5.1-5.8V41.8c0-4.6.4-5.1 5-5.9 34.4-6.4 68.8-12.3 103.2-18.5 5.4-1 10.9-1 16.3-1.6 1.9-.2 2.7.7 3 2.3.3 1.6.3 3.1.3 4.7v196c0 2.4.6 5.1-1.8 7z"
          style={{
            fill: "#c2573c",
          }}
        />
        <path
          className="ppt_svg__st15"
          d="M227.2 159c-1 .7-1.4 1.9-2.6 2.4-4.4 1.9-6.8.4-6.8-4.2 0-29-.1-58 .1-87.1 0-3.1-.7-4.1-3.9-4-23.3.2-46.7 0-70 .2-3.4 0-4.6-.7-4.4-4.3.3-6.4.1-6.4 6.6-6.4h73.3c3.2 0 5.9.6 7.8 3.3-.1 33.3-.1 66.7-.1 100.1z"
        />
        <path
          className="ppt_svg__st15"
          d="M210.7 127.9c0 17.8-.1 35.6.1 53.3 0 3.1-.9 3.8-3.9 3.8-21.2-.1-42.3-.1-63.5 0-2.8 0-4.1-.5-4-3.7.2-7.6 0-7.6 7.6-7.6 12.7 0 25.4-.2 38.1.1 3.9.1 3.1-2.2 3.3-4.4.1-2.4 0-3.9-3.3-3.9-14 .2-28.1 0-42.1.2-2.6 0-3.5-.6-3.5-3.4.1-8.9 0-8.9 8.8-8.9 11.7 0 23.5-.1 35.2.1 3.3.1 4-.9 4-4 0-3.2-.7-4.3-4.2-4.2-13.3.2-26.6 0-39.9.2-2.9 0-4.4-.6-3.8-3.7.1-.4 0-.7 0-1.1 0-4.7 0-4.7 4.4-3.7 15.5 3.6 31.4-7.7 33.1-23.5.3-2.4-.5-3.1-2.9-3.1-7.3.1-14.5-.1-21.8.1-3.1.1-3.8-1-3.8-3.9.2-6.8-.1-13.6.1-20.3.1-2.9-.7-3.5-3.5-2.9-5.7 1.2-5.7 1.1-5.7-4.8 0-7.4 0-7.4 7.4-7.4 20.1 0 40.2.1 60.3-.1 2.8 0 3.7.7 3.7 3.6-.2 17.6-.2 35.4-.2 53.2z"
        />
        <path
          d="M153.2 92.9c0-3.6.1-7.3 0-10.9-.1-2 .4-2.8 2.6-2.6 12.7 1.1 23.7 12.1 24.7 24.7.1 1.9-.4 2.6-2.3 2.6-7.5-.1-15-.1-22.5 0-1.9 0-2.5-.7-2.5-2.5.1-3.8 0-7.5 0-11.3z"
          style={{
            fill: "#fefefe",
          }}
        />
      </g>
      <path
        d="M64.7 134.2v28h-8.4V99.9h13.5c4 0 6.9.2 8.8.7 1.9.5 3.5 1.3 4.9 2.4 1.7 1.4 3.1 3.4 4 5.9 1 2.5 1.5 5.2 1.5 8.1 0 3-.5 5.7-1.5 8.2-1 2.5-2.3 4.5-4 5.9-1.4 1.1-3 1.9-4.9 2.4-1.9.5-4.8.7-8.8.7h-5.1zm.6-8.3h2.3c4.9 0 8.2-.6 9.9-1.8 1.7-1.2 2.5-3.4 2.5-6.5 0-3.3-.9-5.7-2.6-7-1.8-1.4-5-2-9.7-2h-2.3v17.3z"
        style={{
          fill: "#fff",
        }}
      />
    </svg>
  );
}

export default SvgPpt;
