import * as React from "react";

function SvgDocx(props) {
  return (
    <svg
      id="docx_svg__Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x={0}
      y={0}
      viewBox="0 0 240 234"
      style={{
        enableBackground: "new 0 0 240 234",
      }}
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".docx_svg__st25{fill:#1b418b}.docx_svg__st31{fill:#2b5db6}"}
      </style>
      <g id="docx_svg___x36_kVlo1_00000178904173584214510200000007226936569725804968_">
        <path
          d="M55.1 58.5V20.3c0-3.9 2.7-7.6 6.4-8.9 1.2-.4 2.5-.6 3.8-.6h154.9c5.1 0 9.4 3.6 10.1 8.7 0 .2.1.4.1.6V63.8c-.2.2-.5.2-.8.3H129.5c-2 0-4 .1-6.1 0-.1 0-.2 0-.3-.1-.8-.8-1.2-1.8-2.1-2.6-1.8-1.7-4-2.6-6.5-2.7H56.3c-.5.1-.9.2-1.2-.2z"
          style={{
            fill: "#5ba4e7",
          }}
        />
        <path
          d="M230.4 170.1c-2.5-.1-5.1-.1-7.6-.1h-92.9c-.1 0-.2.1-.3.1l-.1-.1c-.3-1.1-.2-2.1-.2-3.2v-49.4c0-.1 0-.2.1-.3.2-.5.7-.4 1.1-.4h98.8c.4 0 .8-.2 1.1.2V170.1z"
          style={{
            fill: "#295bb6",
          }}
        />
        <path
          d="M230.4 117c-2.7 0-5.4-.1-8.1-.1h-92.5c-.1 0-.2 0-.2.1-.3-.2-.3-.6-.3-.9V64.9c0-.3-.1-.7.1-1h101V117z"
          style={{
            fill: "#407ccc",
          }}
        />
        <path
          d="M118.8 123.2c.1 0 .2-.2.2-.3.1-.4.1-.8.1-1.2V68.6c0-1.4-.3-2.7-.8-4-.5-1-1.1-2-1.9-2.8-1.5-1.6-3.3-2.6-5.4-3.1H12.2c-2.7 0-5.2.8-7.1 2.7-2 2-3.1 4.4-3.1 7.2v97c0 .5.1 1 .1 1.5.6 4.4 4.3 8.1 9 8.3 4.2.2 8.4 0 12.7 0h31.3c.5.3 1 .2 1.5.2h52c.8 0 1.5-.1 2.3-.2 3.5-.6 6.9-3.7 7.7-7.1 0-.5.2-1.1.3-1.6.1-1.2.1-2.5.1-3.7v-38.3c0-.5.2-1.1-.2-1.5zm-30 13.1c-.1.9-.2 1.8-.6 2.6-.3.7-.5 1.5-.6 2.3 0 .7-.1 1.3-.4 1.9-.3.7-.5 1.4-.5 2.1 0 .9-.2 1.7-.6 2.5-.1.2-.2.5-.5.6-.2.1-.5.1-.7.1h-12c-.2 0-.4 0-.6-.1-.1 0-.2-.1-.2-.1-.4-.5-.4-1.1-.5-1.7-.2-.7-.2-1.4-.6-2 0-.1.1-.2.1-.2 0-.6-.2-1.1-.3-1.7-.7-2.7-1.3-5.5-2-8.2-.9-3.9-1.9-7.8-2.8-11.7-1.9-8.1-4.2-16-5.4-24.2 0-.3-.1-.8-.5-.7-.4 0-.4.4-.4.8v.2c-.5 3.4-1.1 6.8-1.8 10.1-1.7 7.3-3.3 14.5-4.9 21.8-1.3 5.5-2.5 11.1-3.8 16.6-.2.9-.3 1-1.3 1H36c-.7 0-1-.3-1.2-.9-.5-2.2-1-4.3-1.5-6.5-1.4-5.9-2.8-11.7-4.2-17.6-1.5-6.3-3-12.5-4.4-18.8-1.4-6-2.9-12-4.3-18-.2-.8-.1-.9.7-.9H31c.7 0 1 .3 1.1.9.3 1.6.6 3.1 1 4.7l7.5 36c.5 2.3 1 4.7 1.2 7 .1.7.1 1.5.2 2.2.1.3 0 .8.4.8s.3-.5.4-.8c0-.2.1-.3.1-.5.3-3.1 1-6.2 1.7-9.2l4.8-20.7 4.5-19.2c.3-1.2.3-1.2 1.5-1.2h10.5c1.1 0 1.2 0 1.4 1.1.8 3.3 1.6 6.7 2.4 10 1.3 5.4 2.6 10.7 3.9 16.1l3 12.3c.9 3.5 1.7 6.9 2.1 10.5 0 .4.1.8.2 1.1.1.2.1.3.3.3.2 0 .3-.2.3-.3.1-.2.1-.4.1-.6.2-1.5.3-3 .6-4.4 1-5.4 2.1-10.8 3.1-16.3.9-4.8 1.9-9.6 2.8-14.4.9-4.8 1.9-9.6 2.8-14.4.2-.9.3-1 1.2-1h9.4c1 0 1 .1.8 1-1.5 6.6-3.1 13.2-4.7 19.8-2 8.3-3.9 16.6-5.9 24.9-.3 1.1-.6 2.2-.7 3.3 0 .1.1.1.1.2-.3.4-.2 1-.3 1.5z"
          style={{
            fill: "#224ea7",
          }}
        />
        <path
          className="docx_svg__st25"
          d="M230.4 170.4v-.3c-.2-.4-.6-.2-.9-.2h-99c-.3 0-.7-.1-1 .2-.1.1-.3.2-.5.2-1.4.1-2.9-.2-4.3.1-.5.1-.5.3-.5.5-.1.4 0 .7 0 1.1-.4-.1-.4.3-.4.5-.1.4-.1.8-.2 1.2-.5 1.3-1.1 2.5-2.1 3.6-1.8 2-4.1 2.9-6.8 3.1-1.1.1-2.1-.1-3.1 0s-1.9.2-2.9.2H77.4c-7.2 0-14.4.1-21.7-.1-.2 0-.4 0-.5.2v32.1c0 2.8.8 5.2 2.7 7.3 1.8 1.9 4.2 2.8 6.7 3.2.4.1.8 0 1.2 0h153.1c1.4 0 2.8-.1 4.2-.5 4.1-1.1 7.2-4.7 7.2-9 .1-14.5.1-29 .1-43.4z"
        />
        <path
          d="M124.1 171.9c-.5.6-.3 1.4-.6 2.2-.4.8-.6 1.7-1.2 2.4-.8 1-1.6 2-2.8 2.6-1.6.9-3.3 1.3-5.1 1.4-5.8.3-11.7.2-17.5.2H57.1c-.7 0-1.3-.1-2-.1v-5.3H108.5c4.2 0 7.3-1.8 9.3-5.4.3-.5.5-1.1.7-1.6.1-.1 0-.3.2-.3s.2.1.2.2c0 .3.1.6 0 1-.1.5.1.7.6.6h3.8c.6 0 .9.2.8.9 0 .4.1.8 0 1.2z"
          style={{
            fill: "#0c2042",
          }}
        />
        <path
          d="M124.1 171.9c0-.1-.1-.3-.1-.5.1-1.4 0-1.4-1.4-1.4h-3.3c-.5 0-.7-.2-.6-.7.1-.4.1-.8 0-1.1-.1-.3-.2.1-.3.1-.2-.5.2-1 .2-1.5.3-4.9.1-9.8.1-14.7V67.8c0-1.3-.4-2.5-.8-3.7 0-.3.2-.3.4-.4 1.5 0 3-.1 4.4-.1.1 0 .1.1.2.1h.1c.4.2.5.6.6 1 .4 1.2.6 2.4.6 3.7v48.1c-.1 10.8 0 21.6 0 32.4v20c0 .3-.1.5.1.8 1.1.3 2.3.1 3.4.1.5 0 1.1-.1 1.6.3-.3.7-.8.2-1.2.2-1 .1-2.1 0-3.1 0-.8 0-.9.5-.8 1.1v1.4c-.2-.2 0-.6-.1-.9z"
          style={{
            fill: "#247",
          }}
        />
        <path
          d="M124.3 117c-.3-.2-.1-.6-.1-.8V68.8c0-1.7-.4-3.3-1-4.9h6.2c.2.3.1.6.1.9v51.1c0 .4.1.7.1 1.1-.1 0-.1.1-.2.1-1.6.2-3.2.2-4.8 0-.2 0-.2 0-.3-.1z"
          style={{
            fill: "#3c71b8",
          }}
        />
        <path
          d="M123 63.9c-1.4.2-2.8.2-4.2.2-.3 0-.6-.1-.8.2-.9-1.7-2.1-3.1-3.7-4.2-.9-.6-1.8-.8-2.7-1.2-.2-.1-.4-.1-.5-.3 1.7 0 3.3-.2 5 .1 3.1.6 5.4 2.3 6.9 5.2z"
          style={{
            fill: "#36608a",
          }}
        />
        <path
          d="M72 148.5v-.1c-.2-.6-.3-1.2-.5-1.7-.2-.7-.3-1.4-.5-2.2-.7-3.1-1.5-6.2-2.2-9.3-1.8-7.4-3.5-14.8-5.3-22.2-1.1-4.6-2.3-9.1-3-13.8-.1-.5 0-1-.3-1.6-.2 1.1-.3 2-.5 3-.7 5.2-2 10.3-3.2 15.4-1.9 8.5-3.9 17-5.8 25.6-.5 2.1-1 4.2-1.4 6.3-.1.5-.3.7-.9.7H35.5c-.6 0-.8-.2-.9-.8-1.5-6.6-3.1-13.1-4.7-19.7l-5.1-21.3c-1.7-7-3.3-14-5-21v-.4h11.4c.5 0 .8.1.9.7 2.1 9.9 4.1 19.8 6.2 29.7 1.1 5.2 2.2 10.4 3.2 15.6.3 1.6.4 3.3.6 4.9 0 .3 0 .5.2.9.2-1.3.3-2.5.5-3.6.8-4.6 1.9-9.1 3-13.7 1.9-8.4 3.9-16.8 5.8-25.2.7-2.9 1.4-5.8 2-8.8.1-.4.3-.6.7-.6h12.2c.4 0 .6.2.6.5 1.3 5.3 2.6 10.6 3.8 15.9 1.3 5.3 2.6 10.5 3.9 15.8 1.2 5.3 2.8 10.4 3.6 15.8.2 1 .3 2 .4 3.1 0 .2 0 .4.2.5.1-1.2.3-2.3.4-3.4.4-3.5 1.2-6.9 1.9-10.4 1.6-8.4 3.2-16.8 4.8-25.1.8-3.9 1.5-7.9 2.3-11.8.1-.6.3-.9 1-.9h10.5c.6 0 .7.1.6.7l-6 25.5c-1.6 6.6-3.1 13.2-4.7 19.8-.3 1.2-.5 2.4-.8 3.5-.3.4-.4.9-.3 1.4-.2.9-.3 1.8-.6 2.6-.2.7-.5 1.5-.5 2.3-.2.6-.2 1.3-.5 2s-.5 1.4-.5 2.1c-.2.9-.3 1.8-.6 2.6-.1.2-.1.4-.2.6-.1.2-.4.1-.5.1H72.2c.2.1 0 .1-.2 0z"
          style={{
            fill: "#fefefe",
          }}
        />
        <path
          d="M124.3 117h5.2v53.1c-.5-.1-1-.1-1.5-.2-1.2.2-2.3 0-3.5.1-.2 0-.3-.1-.4-.2 0-2.6.1-5.2.1-7.8v-11.1c0-4-.1-8-.1-12 0-3.3 0-6.6.1-9.9v-11.7c0-.1.1-.2.1-.3z"
          style={{
            fill: "#2552a6",
          }}
        />
        <path
          className="docx_svg__st31"
          d="M72 148.5c2.3.2 4.6.1 6.9.1 2.4 0 4.8.1 7.3-.1-.1.3-.4.2-.6.2H72.8c-.4 0-.6.1-.8-.2zM88.4 138.9c.2-.9.4-1.7.6-2.6-.1.9 0 1.8-.6 2.6zM86.3 147.9c.2-.9.4-1.7.6-2.6 0 .8-.1 1.7-.6 2.6zM87.5 143.1c.1-.7.2-1.3.5-2-.1.8-.1 1.4-.5 2zM71.5 146.6c.3.5.4 1.1.5 1.7-.4-.5-.5-1.1-.5-1.7z"
        />
        <path
          className="docx_svg__st25"
          d="M124.1 169.8c.9.4 1.8.1 2.7.2h1.2c-1.2.3-2.5.1-3.7.1-.1 0-.1-.2-.2-.3z"
        />
      </g>
    </svg>
  );
}

export default SvgDocx;
