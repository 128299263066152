import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";
import loadingIcon from "@iconify/icons-eos-icons/loading";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import { setCredentialCookies, checkCredentialCookies } from "@atd/utils";
import { loginUserAsync } from "@atd/features";

import Auth from "./Auth";

function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [field, setField] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const { state } = location || {};

  const redirect = searchParams.get("redirect");
  const hasToken = searchParams.get("token");
  const { from } = state || {};
  /**
   * Auto login user using details from url parameter
   */
  useEffect(() => {
    if (!hasToken) {
      return;
    }

    const search = location?.search;
    const params = new URLSearchParams(search);
    const paramObj = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const value of params.keys()) {
      paramObj[value] = params.get(value);
    }

    if (Object.keys(paramObj).length > 0) {
      setCredentialCookies(paramObj);
    }
  }, [hasToken, location]);

  useEffect(() => {
    if (from && !checkCredentialCookies()) {
      navigate(`/login?redirect=${from}`);
    }
  }, [navigate, from, redirect]);

  useEffect(() => {
    if (checkCredentialCookies()) {
      // window.open("/requests");
      window.location.href = "/requests";
    }
  }, [navigate]);

  const onChangeInput = (e) => {
    if (e.target.name === "Email") {
      setField({ ...field, email: e.target.value });
      return;
    }
    setField((prevField) => ({
      ...prevField,
      [e.target.name]: e.target.value,
    }));
  };
  // Login Form Submission Handler
  const loginFormSubmitHandler = async (e) => {
    e.preventDefault();

    const { status, message, code } = await dispatch(
      loginUserAsync({ userCredentials: field }),
    ).unwrap();

    if (status === "error") {
      throw Error(message);
    }

    if (code === 201) {
      return;
    }

    if (redirect) {
      window.location.replace(redirect);
    } else {
      window.location.replace("/requests");
    }
  };

  return (
    <Auth
      title="Sign In"
      description={
        <p>
          Need an account? <Link to="/account-setup">Get Started!</Link>
        </p>
      }
    >
      <form onSubmit={loginFormSubmitHandler}>
        <input
          type="text"
          value={field.email}
          name="Email"
          placeholder="Email Address"
          required
          onChange={onChangeInput}
        />
        <input
          type="password"
          value={field.password}
          name="password"
          placeholder="Password"
          required
          onChange={onChangeInput}
        />
        <button type="submit">
          {loading ? <Icon icon={loadingIcon} height={14} /> : "Sign In"}
        </button>
        <p>
          <Link to="/forgot">Forgot Password?</Link>
        </p>
      </form>
    </Auth>
  );
}

export default Login;
