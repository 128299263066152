import React from "react";

import { FileCard } from "@atd/components";
import styled from "styled-components";

import { useDrag } from "react-dnd";

export const Attachments = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;

  & div {
    width: max-content;
    height: auto;
    margin-top: 6px;
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

function Attachment({ file, isDraggable = true, ...rest }) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "file",
    item: { file },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <FileCard
      ref={isDraggable ? drag : null}
      style={{ marginTop: 10, opacity: isDragging ? "0.7" : 1 }}
      type={file.format}
      file={file.link}
      fullName={file.full_name}
      name={file?.name}
      {...rest}
    />
  );
}

export default Attachment;
