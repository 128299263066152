import { useEffect, useRef, useState } from "react";
// import styled from "styled-components";
import uuid from "react-uuid";

import {
  Box,
  Button,
  Badge,
  ToggleBox,
  ContentState,
  Tooltip,
  Typography,
} from "@atd/components";
import { RadioField } from "@atd/components/InputField";
import { CircleInfo } from "@atd/components/Icon/icons";
import { useLocation } from "react-router-dom";
import { useGetUpgradeQuery } from "@atd/features/user";
import UpgradeSubscriptionInfo from "./UpgradeSubscriptionInfo";

function ConfirmPlanItem({
  plan,
  selectedPlan,
  setSelectedPlan,
  setUpdatePlanData,
}) {
  const subInfoRef = useRef();
  const location = useLocation();
  const { subscription_id, si_id } = plan || {};
  const id =
    location?.pathname === "/requests" && subscription_id ? "ul_1" : undefined;
  const params = {
    subscription_id,
    si_id,
    id,
  };

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchUpgradeQuery,
  } = useGetUpgradeQuery(params);
  const { results: upgradeData } = data || {};
  const [upgradePlan, setUpgradePlan] = useState([]);

  useEffect(() => {
    if (upgradeData?.length > 0) {
      const list = upgradeData.map((list) => {
        return {
          ...list,
          plans: list.plans.map((data) => {
            return {
              ...data,
              name: data.name.split("-")[0],
            };
          }),
          name: list.name.split("-")[0],
        };
      });
      console.log("upgrade plan list", list);
      setUpdatePlanData(list);
      setUpgradePlan(list);
    }
  }, [setUpdatePlanData, upgradeData]);

  useEffect(() => {
    refetchUpgradeQuery();
  }, [refetchUpgradeQuery]);

  useEffect(() => {
    if (plan) {
      setSelectedPlan(plan);
    }
    return () => {};
  }, [plan, setSelectedPlan]);

  if (isLoading) {
    return <ContentState text="Loading..." />;
  }

  if (isFetching) {
    return <ContentState text="Fetching data..." />;
  }

  return (
    <ToggleBox>
      <Box>
        {upgradePlan.map(({ plans, name, currency, details }, index) => (
          <Box key={`plan-${name}-${index}`}>
            <Typography as="h3" uiText bold>
              {name} Plan
            </Typography>
            {plans?.length > 0 &&
              plans.map((planItem) => (
                <Box
                  className="PlanItem"
                  key={uuid()}
                  flex={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  padding={{ all: 16 }}
                  margin={{ bottom: 16 }}
                  onClick={() => {
                    setTimeout(() => {
                      subInfoRef.current.scrollIntoView({ behavior: "smooth" });
                    }, 1500);
                    setSelectedPlan(planItem);
                  }}
                  isSelected={planItem?.id === selectedPlan?.id}
                  style={{
                    backgroundColor:
                      planItem?.id === selectedPlan?.id
                        ? "var(--light-color)"
                        : undefined,
                  }}
                >
                  <Box flex={{ alignItems: "center" }}>
                    <RadioField
                      name="plan"
                      checked={planItem?.id === selectedPlan?.id}
                      label={planItem?.name}
                      style={{ pointerEvents: "none" }}
                    />
                    <Tooltip
                      allowHTML
                      content={`<div style='text-align:left;'>${details
                        ?.map((d) => d.description)
                        .join("<br />")}</div>`}
                      style={{ maxWidth: 100 }}
                    >
                      <Button
                        variant="link"
                        withPadding={false}
                        style={{ lineHeight: 0 }}
                      >
                        <CircleInfo style={{ marginLeft: 4 }} />
                      </Button>
                    </Tooltip>
                  </Box>
                  <Box>
                    <div style={{ textAlign: "center" }}>
                      {planItem?.period}
                      {planItem?.has_discount ? (
                        <>
                          <br />
                          <Badge
                            type="success"
                            style={{ borderRadius: "100px" }}
                          >
                            Save {planItem?.discount}%
                          </Badge>
                        </>
                      ) : null}
                    </div>
                  </Box>
                  <Box flex={{ justifyContent: "flex-end" }}>
                    <div style={{ textAlign: "center" }}>
                      {currency}
                      {Math.ceil(planItem?.offer_price)}
                      /m
                      {planItem?.has_discount ? (
                        <>
                          <br />{" "}
                          <span
                            style={{
                              color: "red",
                              textDecoration: "line-through",
                              fontSize: 14,
                            }}
                          >
                            {currency}
                            {planItem?.actual_price}{" "}
                          </span>{" "}
                        </>
                      ) : null}
                    </div>
                  </Box>
                </Box>
              ))}
          </Box>
        ))}
        <UpgradeSubscriptionInfo
          subscriptionId={subscription_id}
          planId={selectedPlan?.id}
        />
        <div ref={subInfoRef} />
      </Box>
    </ToggleBox>
  );
}

export default ConfirmPlanItem;
