import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { useDrop } from "react-dnd";
import styled from "styled-components";

import { useAttachment } from "@atd/hooks";
import { Editor, FileCard, Spinner } from "@atd/components";
import { isAdmin } from "@atd/utils";
import { getRequest } from "@atd/features";
import ReplyService from "@atd/features/request/replyApi";
import DeliverService from "@atd/features/request/deliverApi";

import SelectTemplateField from "./SelectTemplateField";
import EditorBottomToolbar from "../EditorBottomToolbar";

const Board = styled.div`
  overflow: auto;
  width: 100%;
  height: auto;
  min-height: 75px;
  padding: 15px;
  border: 1px ${(props) => (props.isOver ? "dashed #5469d4" : "solid #e0e1e4")};
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > span {
    opacity: 0.5;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 40px;
    align-items: center;
  }
`;

const CreateReplyContainer = styled.div`
  position: relative;
  flex: 1;
  margin-top: 16px;
`;

function CreateReply({
  type,
  index,
  onCacel,
  attachments: currentAttachments,
}) {
  const params = useParams();

  const { values, setFieldValue, isSubmitting, setSubmitting } =
    useFormikContext();

  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [draggedFiles, setDraggedFiles] = useState([]);
  const [allAttachments, setAllAttachments] = useState([]);

  const dispatch = useDispatch();

  const { attachments, isUploading, onSetAttachments, onRemoveAttachment } =
    useAttachment(currentAttachments, { request_id: params.id });

  useEffect(() => {
    setAllAttachments([...attachments, ...draggedFiles]);
  }, [attachments, draggedFiles]);

  const addFileToBoard = (file) => {
    setDraggedFiles((prevDraggedFiles) => [...prevDraggedFiles, file]);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: (files) => {
      onSetAttachments(files);
    },
  });
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "file",
    drop: (item) => addFileToBoard(item.file),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  const onSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const service = type === "delivery" ? DeliverService : ReplyService;
      const { status, results } = await service.add(params.id, {
        body: values.threads[index].body,
        attachments: allAttachments,
      });
      if (status === "success") {
        await dispatch(getRequest(params.id));
      }
      setFieldValue(`threads.${index}`, {
        ...results,
        isNew: false,
        isEditing: false,
        isOpen: true,
        type,
      });
      setDraggedFiles([]);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CreateReplyContainer {...getRootProps()}>
      <div id="editor" style={{ flex: 1 }}>
        <Editor
          disabled={isSubmitting}
          requestId={params.id}
          model={values.threads[index].body}
          hasCustomBottomToolbar
          onModelChange={(model) =>
            setFieldValue(`threads.${index}.body`, model)
          }
        />
      </div>
      <EditorBottomToolbar
        open={open}
        body={values.threads[index].body}
        showFileTypes={false}
        showVersionsDropdown={false}
        showAttachmentsButton={!isAdmin()}
        inputProps={{ ...getInputProps() }}
        onSubmit={onSubmit}
        onCancel={onCacel}
        render={() =>
          isAdmin() && (
            <SelectTemplateField
              value={selectedTemplate}
              onChange={(item) => {
                setSelectedTemplate(item.id);
                setFieldValue(`threads.${index}.body`, item.body);
                setFieldValue(`threads.${index}.attachments`, item.attachments);
              }}
            />
          )
        }
      />
      <Board ref={drop} isOver={isOver}>
        {isUploading && <Spinner style={{ width: "100%" }} />}
        {allAttachments?.length > 0 &&
          allAttachments.map((file, i) => (
            <FileCard
              key={file.link}
              type={file.format}
              file={file.link}
              fullName={file.full_name}
              name={file?.name}
              style={{ marginBottom: 0 }}
              onRemove={() => {
                onRemoveAttachment(i);

                const updatedDraggedAttachments = [...draggedFiles];
                if (i > -1) {
                  updatedDraggedAttachments.splice(i, 1); // 2nd parameter means remove one item only
                }
                setDraggedFiles(updatedDraggedAttachments);
              }}
            />
          ))}
        {allAttachments?.length === 0 && (
          <span>Drag and drop files from other activities here</span>
        )}
      </Board>
    </CreateReplyContainer>
  );
}

export default CreateReply;
