import styled from "styled-components";

import { Modal, Table, ExternalLink } from "@atd/components";
import { CircleCheck, Lock } from "@atd/components/Icon/icons";

const DisabledText = styled.span`
  opacity: 0.6;
`;

function PlanDetailsModal({ title, show, onClose }) {
  return (
    <Modal
      title={title}
      isOpen={show}
      onClose={onClose}
      hasFooter={false}
      width={1300}
    >
      <div className="pd16">
        <p>
          Flexible plans for scalable teams that need help in speeding up their
          creative output and eliminating design bottlenecks.
        </p>
        <p className="title2 mgt2">List of Plans:</p>
        <Table hasBorder className="table wt-100 mgb10 wt-half mgt1">
          <thead>
            <tr className="left">
              <th className="title2 fnt-14 plan_details">Standard Plan</th>
              <th className="title2 fnt-14 plan_details">Pro Plan</th>
              <th className="title2 fnt-14 plan_details">Premium Plan</th>
              <th className="title2 fnt-14 plan_details">Ultimate Plan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Graphic Design
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Custom Illustrations
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Presentation Designs</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Motion Graphics & Animations</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Landing Pages & UI Designs</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Front End Development</DisabledText>
                </div>
              </td>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Graphic Design
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Custom Illustrations
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Presentation Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Motion Graphics & Animations</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Landing Pages & UI Designs</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Front End Development</DisabledText>
                </div>
              </td>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Graphic Design
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Custom Illustrations
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Presentation Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Motion Graphics & Animations
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Landing Pages & UI Designs</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Front End Development</DisabledText>
                </div>
              </td>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Graphic Design
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Custom Illustrations
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Presentation Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Motion Graphics & Animations
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Landing Pages & UI Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Front End Development
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p className="title2 mgt2">Top Features:</p>
        <Table hasBorder className="table wt-100 mgb10 wt-half mgt1">
          <thead>
            <tr className="left">
              <th className="title2 fnt-14 plan_details">Standard Plan</th>
              <th className="title2 fnt-14 plan_details">Pro Plan</th>
              <th className="title2 fnt-14 plan_details">Premium Plan</th>
              <th className="title2 fnt-14 plan_details">Ultimate Plan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="plan_details">
                <div className="mgl--10">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Requests
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Professional Designers
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Ownership for Your Files
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  7 Days Risk Free Trial
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Support
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Champions Multiple Brands
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Instant Access to Super Intuitive Platform
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  2 Days Turnaround
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Dedicated Account Manager</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Dedicated Designer</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    Real-time Slack Chat with <br />
                    &ensp;&nbsp;&nbsp; the Designer
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Dedicated Creative Director</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    White Glove Customer <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Onboarding
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    Status Meeting with the <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Creative Director
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Multiple Requests at a Time</DisabledText>
                </div>
              </td>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Requests
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Professional Designers
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Ownership for Your Files
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  7 Days Risk Free Trial
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Support
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Champions Multiple Brands
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Instant Access to Super Intuitive Platform
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Same Day Turnaround
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Account Manager
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Designer
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    Real-time Slack Chat with <br />
                    &ensp;&nbsp;&nbsp; the Designer
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Dedicated Creative Director</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    White Glove Customer <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Onboarding
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    Status Meeting with the <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Creative Director
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Multiple Requests at a Time</DisabledText>
                </div>
              </td>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Requests
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Professional Designers
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Ownership for Your Files
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  7 Days Risk Free Trial
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Support
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Champions Multiple Brands
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Instant Access to Super Intuitive Platform
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Same Day Turnaround
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Account Manager
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Designer
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Real-time Slack Chat with <br />
                  &ensp;&nbsp;&nbsp;&nbsp; the Designer
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <Lock />
                  </span>{" "}
                  <DisabledText> Dedicated Creative Director</DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    White Glove Customer <br />
                    &nbsp;&nbsp;&nbsp;&nbsp; Onboarding
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <Lock />
                  </span>{" "}
                  <DisabledText>
                    Status Meeting with the <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Creative Director
                  </DisabledText>
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <Lock />
                  </span>{" "}
                  <DisabledText>Multiple Requests at a Time</DisabledText>
                </div>
              </td>
              <td className="plan_details">
                <div>
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Requests
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Unlimited Designs
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Professional Designers
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Ownership for Your Files
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  21 Days Risk Free Trial
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Support
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Champions Multiple Brands
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Instant Access to Super Intuitive Platform
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  12 Hours Turnaround
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Account Manager
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Designer
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Real-time Slack Chat with <br />
                  &ensp;&nbsp;&nbsp;&nbsp; the Designer
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Dedicated Creative Director
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  White Glove Customer <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Onboarding
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span style={{ verticalAlign: "middle", marginRight: 5 }}>
                    <CircleCheck />
                  </span>{" "}
                  Status Meeting with the <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Creative Director
                </div>
                <div className="mgt1 Link--wrap--false">
                  <span
                    style={{ verticalAlign: "text-bottom", marginRight: 5 }}
                  >
                    <CircleCheck />
                  </span>{" "}
                  Multiple Requests at a Time
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
        <p className="mgt2">
          Click{" "}
          <ExternalLink
            to="https://help.alltimedesign.com/en/articles/9004400-an-array-of-all-time-design-s-services"
            target="_blank"
          >
            here
          </ExternalLink>{" "}
          to understand what kind of requests you can and can&rsquo;t send our
          way.
        </p>
      </div>
    </Modal>
  );
}

export default PlanDetailsModal;
