import React from "react";
import styled from "styled-components";

import { Box, FileCard, Spinner } from "@atd/components";

const Progress = styled(Box)`
  text-indent: -9999px;
  height: 2px;
  width: ${({ progress }) => progress}%;
  background: #5469d4;
  margin: 0;
`;

const File = styled.div`
  & > div {
    margin-right: 0;
    margin-bottom: 5px;
  }
`;

function AttachmentsBox({ progress, attachments, uploading, onRemove }) {
  if (attachments?.length === 0 && !uploading) {
    return null;
  }

  return (
    <div id="mainn" style={{ display: "flex" }}>
      {uploading && attachments?.length === 0 && (
        <Box
          flex={{ alignItems: "center", justifyContent: "center" }}
          padding={{ vertical: 8 }}
          margin={{ bottom: 8 }}
          style={{ flex: 1 }}
        >
          <Spinner />{" "}
        </Box>
      )}
      {attachments?.length > 0 &&
        attachments.map((file, index) => (
          <File>
            <FileCard
              key={file.link}
              type={file.format}
              file={file.link}
              fullName={file.full_name}
              name={file?.name}
              onRemove={() => onRemove(index)}
            />
          </File>
        ))}
      <Box style={{ width: "100%", margin: 0 }}>
        <Progress progress={progress} />
      </Box>
    </div>
  );
}

export default AttachmentsBox;
