import { ExternalLink, FormControl } from "@atd/components";

import { FeidRowStyle, FormControlStyle } from "./ProfileForm.style";

function ChangeProfileForm({ isLoading, user }) {
  return (
    <div id="MerchantInfoForm">
      <div className="atd-Fieldset-wrapper Box-root Margin-bottom--2">
        <fieldset className="atd-Fieldset">
          <FeidRowStyle className="atd-Fieldset-rows">
            <FormControlStyle
              as={FormControl}
              disabled={isLoading}
              control="input"
              name="first"
              label="First Name"
              placeholder="First name..."
            />
            <FormControlStyle
              as={FormControl}
              disabled={isLoading}
              control="input"
              name="last"
              label="Last Name"
              placeholder="Last name..."
            />
            <FormControlStyle
              as={FormControl}
              control="input"
              name="email"
              label="Email Address"
              placeholder="Enter email here..."
            />
            <FormControlStyle
              as={FormControl}
              disabled={isLoading || [3, 4].includes(user?.role)}
              control="input"
              name="company"
              label="Company"
              autoComplete="off"
              placeholder="Account name"
            />
            <FormControlStyle
              as={FormControl}
              disabled={isLoading}
              control="input"
              name="phone"
              label="Phone Number"
              placeholder="+1-234-567-8901"
            />
            <FormControlStyle className="atd-Fieldset-row">
              <label htmlFor="avatar" className="atd-Fieldset-label mgt1">
                Profile Image
              </label>
              <div className="bs-Fieldset-field">
                <p className="bs-Fieldset-explanation">
                  <img src={user?.user_icon} alt="ATD" className="acc-avt" />
                </p>
                <div style={{ marginLeft: 70, marginTop: 6 }}>
                  You can change your profile image at{" "}
                  <ExternalLink
                    to="https://en.gravatar.com/site/signup/"
                    target="_blank"
                    className="UnstyledLink InlineLink Text-color--blue"
                  >
                    Gravatar.com
                  </ExternalLink>
                  , using
                  <br />
                  the email address listed below.
                </div>
                <p />
              </div>
            </FormControlStyle>
          </FeidRowStyle>
        </fieldset>
      </div>
    </div>
  );
}

export default ChangeProfileForm;
