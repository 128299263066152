import { useFormikContext } from "formik";

import { Button, InputField } from "@atd/components";

const { Select } = InputField;

function EditorBottomToolbar({
  open,
  body,
  version,
  versions,
  showVersionsDropdown,
  showAttachmentsButton,
  inputProps,
  onSubmit,
  onCancel,
  render,
  onChangeVersion,
}) {
  const { isSubmitting } = useFormikContext();

  return (
    <div className="bottom-toolbar" style={{ display: "flex" }}>
      {render && render()}
      <table className="table wt-100">
        <tbody>
          <tr>
            <td>
              {showVersionsDropdown && (
                <Select
                  name="version"
                  value={version || ""}
                  options={versions}
                  onChange={onChangeVersion}
                />
              )}
            </td>
            {/* {showFileTypes && (
              <td>
                <RequiredFormats />
              </td>
            )} */}
            <td className="wt-20">
              <div className="Box-root Flex-flex Flex-direction--row Flex-justifyContent--flexEnd Flex-wrap--nowrap mgt-8">
                {showAttachmentsButton && (
                  <div className="upload-fbtn-wrapper mgl-7">
                    <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-bottom--8 Margin-right--8 Margin-left--8 customcss4">
                      <Button
                        type="button"
                        icon="plus"
                        variant="default"
                        label="Attach"
                        onClick={open}
                      />
                      <input name="myfile" id="fileinput" {...inputProps} />
                    </div>
                  </div>
                )}
                <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--8 pointer1">
                  <Button
                    type="button"
                    variant="default"
                    label="Cancel"
                    disabled={isSubmitting}
                    onClick={onCancel}
                  />
                </div>
                <div className="Box-root Box-hideIfEmpty Margin-top--12 Margin-left--8 pointer1">
                  <Button
                    type="button"
                    disabled={isSubmitting || body?.length === 0}
                    variant="primary"
                    icon="arrow-top-right"
                    label="Submit"
                    iconColor="white"
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

EditorBottomToolbar.defaultProps = {
  showFileTypes: true,
  showVersionsDropdown: true,
  showAttachmentsButton: true,
};

export default EditorBottomToolbar;
