/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from "react";
import moment from "moment";
import uuid from "react-uuid";
import { Formik, Form, Field } from "formik";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Box, Button, CheckBox } from "@atd/components";
import { Select } from "@atd/components/InputField";
import { updateClient } from "@atd/features";
import { calculateExpireDate } from "@atd/utils";

const LabelStyle = styled.label`
  margin-left: 25px;
  margin-top: -3px;
`;

const FieldWrapperStyle = styled.div`
  padding-left: 0px;
  display: flex;
`;

export function FieldSet({ field, form, id, label, ...props }) {
  return (
    <div className="atd-Fieldset-row">
      <FieldWrapperStyle>
        <CheckBox
          checked={field.value}
          id={`${field.name}-${id || ""}`}
          {...field}
          {...props}
        />
        <LabelStyle htmlFor={`${field.name}-${id || ""}`}>{label}</LabelStyle>
      </FieldWrapperStyle>
    </div>
  );
}

export function Title({ children, className, ...rest }) {
  return (
    <div className={`client-title ${className}`} {...rest}>
      {children}
    </div>
  );
}

function CompanyDetails({ company, client }) {
  const [formValues, setFormValues] = useState();

  const { company_details, subscription_list } = client || {};

  const dispatch = useDispatch();

  useEffect(() => {
    setFormValues({
      source: "",
    });
  }, []);

  const onUpdateCompanyDetails = async (values, { setSubmitting }) => {
    await dispatch(
      updateClient({
        id: company,
        data: {
          update_type: "card_and_invoice",
          ...values,
        },
      }),
    );
    setSubmitting(false);
  };

  const initialValues = {
    source: "",
  };

  if (!company_details && !subscription_list) {
    return null;
  }

  return (
    <>
      <Title>Address</Title>
      <table className="table mb-0 wt-100">
        <thead>
          <tr>
            <th scope="col" className="border-0 tb-title pl5">
              COMPANY NAME
            </th>
            <th scope="col" className="border-0 tb-title pl5">
              STREET
            </th>
            <th scope="col" className="border-0 tb-title pl5">
              CITY
            </th>
            <th scope="col" className="border-0 tb-title pl5">
              COUNTRY
            </th>
            <th scope="col" className="border-0 tb-title pl5">
              POSTAL CODE
            </th>
            {company_details.trial_status === 1 && (
              <th scope="col" className="border-0 tb-title pl5">
                TRAIL PERIOD
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{company_details.company_name}</td>
            <td>{company_details.street}</td>
            <td>{company_details.city}</td>
            <td>{company_details.country}</td>
            <td>{company_details.postel_code}</td>
          </tr>
        </tbody>
      </table>
      <Title className="mgt4">Subscription List</Title>
      <table className="table mb-0 wt-100">
        <thead>
          <tr>
            <th scope="col" className="border-0 tb-title pl5">
              PLAN NAME
            </th>
            <th scope="col" className="border-0 tb-title pl5">
              Subscriptions
            </th>
            <th scope="col" className="border-0 tb-title pl5">
              CREATED
            </th>
            {company_details.trial_status === 1 && (
              <th scope="col" className="border-0 tb-title pl5">
                TRAIL PERIOD
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {subscription_list?.map((list) => (
            <tr key={uuid()}>
              <td>{list?.name}</td>
              <td>{list?.quantity}</td>
              <td>{moment(list.created).format("YYYY-MM-DD")}</td>
              {company_details.trial_status === 1 && (
                <td>
                  {calculateExpireDate(company_details.trial_start)}
                  &nbsp;-&nbsp;
                  {calculateExpireDate(company_details.trial_end)}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <Title className="mgt4">Source</Title>
      <Box className="ContentBlock" id="clint">
        <Formik
          initialValues={formValues || initialValues}
          onSubmit={onUpdateCompanyDetails}
          enableReinitialize
        >
          {({ isSubmitting }) => (
            <Form>
              <div id="MerchantInfoForm">
                <Box margin={{ bottom: 2 }} className="atd-Fieldset-wrapper">
                  <Box margin={{ left: 8 }} padding={{ top: 8 }}>
                    <div className="atd-Fieldset-rows">
                      <Field
                        name="source"
                        component={Select}
                        options={[
                          { label: "Website", value: "website" },
                          { label: "Instagram", value: "instagram" },
                          { label: "FB Ads", value: "fb-ads" },
                          { label: "Organic SEO", value: "organic-seo" },
                          { label: "PPC", value: "ppc" },
                          { label: "Youtube Ads", value: "youtube-ads" },
                          { label: "Community", value: "community" },
                        ]}
                      />
                    </div>
                  </Box>
                  <Box
                    flex={{
                      alignItems: "stretch",
                      direction: "row",
                      justifyContent: "flex-end",
                    }}
                    padding={{ horizontal: 20, vertical: 16 }}
                    className="ContentFooter"
                  >
                    <Box flex={{ display: "flex" }} className="fltrgt">
                      <Box hideIfEmpty className="pointer1">
                        <Button
                          type="submit"
                          variant="primary"
                          disabled={isSubmitting}
                        >
                          Update
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
    </>
  );
}

export default CompanyDetails;
